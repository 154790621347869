/** @format */

import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Title from "../../UI/Title";
//import { DropzoneArea } from "material-ui-dropzone";
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Drawer,
  FormGroup,
  Grid,
  Paper,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Zoom,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import errorStyles from "../../UI/ErrorStyles";
import AddCircleSharpIcon from "@mui/icons-material/AddCircleSharp";
import ProgressBar from "../../UI/ProgressBar";
import NumberFormat from "react-number-format";
import EmptyEstimatesDialog from "../../UI/EmptyEstimatesDialog";
import { Autocomplete } from "@mui/material";
import {
  createPlan,
  deletePlan,
  getFileByUSingUrl,
  updatePlan,
  uploadDocumentFile,
} from "./takeoff.helpers";
import Webviewer from "./Webviewer";
import EditIcon from "@mui/icons-material/Edit";
import Slide from "@mui/material/Slide";
import { StyledDropZone } from "./StyledDropZone";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomizedSnackbars from "../../UI/CustomizedSnackbars";
import SimpleBackdrop from "../../UI/SimpleBackdrop";
import MUIDataTable from "mui-datatables";
import LineItemsTableForPlans from "./LineItemsTableForPlans";
import { styled } from "material-ui-core";
import { getEstimateLineItemsList } from "../EstimateCosting/estimate.costings";
import {
  getEstimateLineItemsWithGroup,
  getJobPlanItemByEstimateJobPlanId,
} from "../../cost-center/job-plan/jobplan.helpers";
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

function preventDefault(event) {
  event.preventDefault();
}

const useStyles = makeStyles({
  Drawerroot: {
    width: "100%",
    padding: 16,
  },
  depositContext: {
    flex: 1,
  },
  textField: {
    width: "10%",
  },
  MUITableWidth: {
    maxWidth: "100%",
  },
});
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Plans({ estimateId, estimateStatus }) {
  const classes = useStyles();
  const ErrorClasses = errorStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  /* ---------------------------------------------------------------------------------- */

  const [load, setLoad] = React.useState(true);
  const [planObject, setPlanObject] = React.useState([]);
  const [openPlanDialog, setOpenPlanDialog] = useState(false);
  const [autoSelectPlanName, setAutoSelectPlanName] = useState(null);
  const [distanceValue, setDistanceValue] = useState();
  const [isNewPlanButton, setIsNewPlanButton] = useState(true);
  const [wantToCallUseEffect, setWantToCallUseEffect] = useState(true);
  const [instance, setInstance] = useState();
  const viewer = useRef(null);
  const [planName, setPlanName] = useState("");
  const [planFile, setPlanFile] = useState();
  const [fileName, setFileName] = useState("");
  /* state for snackbar */
  const [openCustSnackbar, setOpenCustSnackbar] = useState(false);
  const [snackbarMSG, setSnackbarMSG] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("");
  const [roundLoader, setRoundLoader] = useState(false);
  const [addMeasurementData, setAddMeasurementData] = useState([]);
  const [subMeasurementData, setSubMeasurementData] = useState([]);
  const [addMeasurementTotal, setAddMeasurementTotal] = useState(0);
  const [subMeasurementTotal, setSubMeasurementTotal] = useState(0);
  const [checked, setChecked] = React.useState(true);
  const [isAdd, setIsAdd] = React.useState(true);
  const [newMeasurmentUpdated, setNewMeasurmentUpdated] = React.useState({});
  const [lineItemsList, setLineItemsList] = useState([]);
  const [lineItemLoad, setLineItemLoad] = useState(true);
  const [finalMeasurement, setFinalMeasurement] = useState(0);
  const [estimateLineItems, setEstimateLineItems] = useState([]);
  const [dummyLoad, setDummyLoad] = useState(true); //its a only for dummy
  const [deletedMeasurement, setDeletedMeasurement] = useState(0);
  const [allMeasurements, setAllMeasurements] = useState([]);
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [addOrSub, setAddOrSub] = React.useState(false);

  const handleAdd = () => {
    setShowConfirm(false);

    setAllMeasurements((current) => [...current, { ...newMeasurmentUpdated, checked: true }]);
  };

  const handleSubtract = () => {
    setShowConfirm(false);

    setAllMeasurements((current) => [...current, { ...newMeasurmentUpdated, checked: false }]);
  };

  useEffect(() => {
    getEstimateLineItemsList(setLineItemsList, estimateId, setLineItemLoad);
    getEstimateLineItemsWithGroup(setEstimateLineItems, estimateId);
  }, []);
  // -------------------------------------------------
  useEffect(() => {
    let total = 0;
    addMeasurementData?.map((item) => {
      total = total + item.measurment;
    });
    setAddMeasurementTotal(total);
  }, [addMeasurementData]);
  // -------------------------------------------------

  useEffect(() => {
    let total = 0;
    if (addMeasurementTotal > subMeasurementTotal) {
      total = addMeasurementTotal - subMeasurementTotal;
    } else {
      total = subMeasurementTotal - addMeasurementTotal;
    }
    setFinalMeasurement(total);
  }, [addMeasurementTotal, subMeasurementTotal]);
  // -------------------------------------------------

  useEffect(() => {
    let total = 0;
    subMeasurementData.map((item) => (total = total + item.measurment));
    setSubMeasurementTotal(total);
  }, [subMeasurementData]);
  // -------------------------------------------------
  useEffect(() => {
    console.log("deletedMeasurement type", typeof deletedMeasurement);

    let addData = addMeasurementData.filter((item) => {
      console.log("typeof Item", typeof item.measurment);

      return item?.id !== deletedMeasurement;
    });
    let subData = subMeasurementData.filter((item) => item.measurment !== deletedMeasurement);
    const filteredData = allMeasurements.filter(
      (measurement) => measurement.id !== deletedMeasurement
    );
    setAllMeasurements(filteredData);
    setAddMeasurementData(addData);
    setSubMeasurementData(subData);
  }, [deletedMeasurement]);
  // -------------------------------------------------
  useEffect(() => {
    if (Object.keys(newMeasurmentUpdated).length !== 0) {
      setShowConfirm(true);

      // setAllMeasurements((current) => [...current, { ...newMeasurmentUpdated, checked: addOrSub }]);
      // setAddMeasurementData((current) => [...current, newMeasurmentUpdated]);
      // if (isAdd) {
      //   setAddMeasurementData((current) => [...current, newMeasurmentUpdated]);
      // } else {
      //   setSubMeasurementData((current) => [...current, newMeasurmentUpdated]);
      // }
    }
  }, [newMeasurmentUpdated]);

  useEffect(() => {
    let sum = 0;
    allMeasurements.forEach((obj) => {
      sum += obj.measurment;
    });
    setFinalMeasurement(sum.toFixed(2));
  }, [allMeasurements]);
  // -------------------------------------------------

  const handleChangeTableToggle = (isChecked, measurement, index) => {
    let tempMeasures = allMeasurements;
    tempMeasures[index] = { ...tempMeasures[index], checked: isChecked };
    setAllMeasurements(tempMeasures);

    isChecked
      ? setFinalMeasurement(finalMeasurement + measurement)
      : setFinalMeasurement(finalMeasurement - measurement);
  };

  useEffect(() => {
    getFileByUSingUrl(
      estimateId,
      instance,
      setPlanObject,
      autoSelectPlanName,
      setAutoSelectPlanName,
      setLoad,
      planName
    );

    return () => {
      setPlanName("");
      setOpenCustSnackbar(false);
    };
  }, [wantToCallUseEffect]);
  /* ---------------------------------------------------------------------------------- */

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setState({ ...state, [anchor]: open });
    let row = {};
    reset(row);
  };
  /* ---------------------------------------------------------------------------------- */
  const handleOpenDrawer = (name) => {
    console.log("first", name);
    if (name === "newPlan") {
      reset({});
      setIsNewPlanButton(true);
    } else {
      if (!autoSelectPlanName.planName) {
        reset({ planName: planObject[0].planName });
      } else {
        reset({ planName: autoSelectPlanName.planName });
      }
      setIsNewPlanButton(false);
    }
    setState({ ...state, ["right"]: true });
  };
  /* ---------------------------------------------------------------------------------- */

  const handleClose = () => {
    setState({ ...state, ["right"]: false });
  };

  const handleDeletePlan = () => {
    deletePlan(
      estimateId,
      autoSelectPlanName,
      setAutoSelectPlanName,
      planObject,
      wantToCallUseEffect,
      setWantToCallUseEffect,
      setRoundLoader,
      setSnackbarMSG,
      setOpenCustSnackbar,
      setSnackbarSeverity
    );
  };

  const handleSaveMeasure = () => {
    // distanceValue;
    // setOpenPlanDialog(false);
  };

  /* ---------------------------------------------------------------------------------- */
  const {
    handleSubmit,
    control,
    getValues,
    reset,
    formState: { errors },
  } = useForm();
  /* ---------------------------------------------------------------------------------- */
  const submitNewPlan = (data) => {
    let finalData = { planName: data.planName, file: planFile };

    if (isNewPlanButton) {
      setPlanName(data.planName);

      createPlan(
        estimateId,
        finalData,
        uploadDocumentFile,
        setWantToCallUseEffect,
        setOpenCustSnackbar,
        setSnackbarMSG,
        setSnackbarSeverity,
        setRoundLoader,
        setState,
        state
      );
    } else {
      //update job plan API method here
      updatePlan(
        estimateId,
        finalData,
        uploadDocumentFile,
        setWantToCallUseEffect,
        setOpenCustSnackbar,
        setSnackbarMSG,
        setSnackbarSeverity,
        setRoundLoader,
        setState,
        state
      );
    }
  };
  /* ---------------------------------------------------------------------------------- */
  const callBackFunction = () => {
    console.log("callbackfun");
  };

  // ----------------------------------------
  const columnsAddMeasurment = [
    {
      name: "measurment",
      label: "Measurement",
      options: {
        filter: true,
        sort: true,
        display: true,
        // setCellProps: () => ({ style: { minWidth: "800px", maxWidth: "800px" } }),
      },
    },
  ];
  const columnsDeleteMeasurment = [
    {
      name: "measurment",
      label: "Measurement",
      options: {
        filter: true,
        sort: true,
        display: true,
        // setCellProps: () => ({ style: { minWidth: "800px", maxWidth: "800px" } }),
      },
    },
  ];
  // -----------------------------------------------------------------------

  const addOptions = {
    tableBodyHeight: "auto",
    selectableRowsOnClick: true,
    responsive: "standard",
    rowsPerPageOptions: [10, 15, 20],
    rowsPerPage: "10",
    selectableRows: false,
    filter: false,
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    selectableRowsOnClick: false,
    selectableRows: "none",
    expandableRows: false,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    customFooter: () => {
      return <div>Total:{addMeasurementTotal} </div>;
    },
    textLabels: {
      pagination: {
        next: "Next >",
        previous: "< Previous",
        rowsPerPage: "Total items Per Page",
        displayRows: "OF",
      },
    },
  };
  const subOptions = {
    tableBodyHeight: "auto",
    selectableRowsOnClick: true,
    responsive: "standard",
    rowsPerPageOptions: [10, 15, 20],
    rowsPerPage: "10",
    selectableRows: false,
    filter: false,
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    selectableRowsOnClick: false,
    selectableRows: "none",
    expandableRows: false,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    customFooter: () => {
      return <div>Total:{subMeasurementTotal} </div>;
    },
    textLabels: {
      pagination: {
        next: "Next >",
        previous: "< Previous",
        rowsPerPage: "Total items Per Page",
        displayRows: "OF",
      },
    },
  };
  // -----------------------------------------------------------------------
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.mode === "dark" ? "rgba(255,255,255,.35)" : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));
  // -----------------------------------------------------------------------
  console.log("allMeasurements::", allMeasurements);
  return (
    <React.Fragment>
      {/* <Typography variant="h6" gutterBottom>Plans</Typography> */}
      <Title title="Plans" />
      {roundLoader && <SimpleBackdrop />}
      {load ? (
        <ProgressBar />
      ) : (
        <Grid>
          <Paper className={classes.paper} elevation={0}>
            <Grid container sx={{ p: 2 }} alignItems="center">
              <Grid item xs={4} md={5} lg={3}>
                {planObject.length !== 0 && (
                  <Autocomplete
                    options={planObject}
                    disableClearable
                    value={autoSelectPlanName}
                    size="small"
                    id="planName"
                    onChange={(event, newValue) => {
                      setAutoSelectPlanName(newValue);
                      instance.UI.loadDocument(newValue.documentUrl);
                    }}
                    getOptionLabel={(option) => option.planName}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" name="planName" />
                    )}
                  />
                )}
              </Grid>

              <Grid item xs={9}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "right",
                  }}
                >
                  {planObject.length == 0 ? (
                    <ButtonGroup variant="contained">
                      <Tooltip title="ADD PLAN" placement="top" TransitionComponent={Zoom} arrow>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          className={classes.button}
                          onClick={(e) => handleOpenDrawer("newPlan")}
                          disabled={estimateStatus !== "DRAFT" ? true : false}
                        >
                          <AddCircleSharpIcon fontSize="small" />
                        </Button>
                      </Tooltip>

                      <Button
                        variant="contained"
                        color="warning"
                        size="small"
                        className={classes.button}
                        onClick={(e) => handleOpenDrawer("updatePlan")}
                        disabled={estimateStatus !== "DRAFT" ? true : false}
                      >
                        <EditIcon fontSize="small" />
                      </Button>

                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        className={classes.button}
                        onClick={handleDeletePlan}
                        disabled={estimateStatus !== "DRAFT" ? true : false}
                      >
                        <DeleteIcon fontSize="small" />
                      </Button>
                    </ButtonGroup>
                  ) : (
                    <ButtonGroup variant="contained">
                      <Tooltip title="ADD PLAN" placement="top" TransitionComponent={Zoom} arrow>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          className={classes.button}
                          onClick={(e) => handleOpenDrawer("newPlan")}
                        >
                          <AddCircleSharpIcon fontSize="small" />
                        </Button>
                      </Tooltip>
                      <Tooltip title="EDIT PLAN" placement="top" TransitionComponent={Zoom} arrow>
                        <Button
                          variant="contained"
                          color="warning"
                          size="small"
                          className={classes.button}
                          onClick={(e) => handleOpenDrawer("updatePlan")}
                        >
                          <EditIcon fontSize="small" />
                        </Button>
                      </Tooltip>
                      <Tooltip title="DELETE PLAN" placement="top" TransitionComponent={Zoom} arrow>
                        <Button
                          variant="contained"
                          color="secondary"
                          size="small"
                          className={classes.button}
                          onClick={handleDeletePlan}
                        >
                          <DeleteIcon fontSize="small" />
                        </Button>
                      </Tooltip>
                    </ButtonGroup>
                  )}
                </div>
              </Grid>
            </Grid>
          </Paper>
          {estimateStatus === "DRAFT" && (
            <Grid item xs={12}>
              <Paper className={classes.paper} elevation={1} sx={{ mt: 2 }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Title title="Take Off Tool" />
                </div>
                <Grid item xs={12} container spacing={2}>
                  <Grid item xs={12}>
                    <div style={{ minWidth: 275, margin: 7 }}>
                      <Table
                        sx={{
                          borderBottom: "none",
                          justifyContent: "center",
                          overflowX: "auto",
                        }}
                        size="small"
                        aria-label="a dense table"
                        id="estimationCosting"
                        className={" estimationCosting"}
                      >
                        <TableHead>
                          <TableRow>
                            {/* <TableCell align="left" width="15%"></TableCell> */}
                            <TableCell align="center" width="auto">
                              Measurements
                            </TableCell>
                            <TableCell align="center" width="12%">
                              Action
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {allMeasurements.map((obj, index) => {
                            return (
                              <TableRow hover>
                                <TableCell width="15%" align="center">
                                  {console.log("obj?.measurement:", obj?.measurment)}
                                  {obj?.measurment}
                                </TableCell>
                                <TableCell align="center">
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                    }}
                                  >
                                    <FormGroup sx={{ mr: 2, mb: 2 }}>
                                      <Stack direction="row" spacing={1} alignItems="center">
                                        <Typography>Subtract</Typography>
                                        <AntSwitch
                                          checked={obj?.checked}
                                          inputProps={{
                                            "aria-label": "ant design",
                                          }}
                                          onChange={(e) =>
                                            handleChangeTableToggle(
                                              e.target.checked,
                                              obj?.measurment,
                                              index
                                            )
                                          }
                                        />
                                        <Typography>Add</Typography>
                                      </Stack>
                                    </FormGroup>
                                  </div>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                          <TableRow hover>
                            <TableCell width="15%" align="center">
                              Total: {finalMeasurement}
                            </TableCell>
                            <TableCell width="15%" align="center"></TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <LineItemsTableForPlans
                      lineItemsList={lineItemsList}
                      finalMeasurement={finalMeasurement}
                      estimateLineItems={estimateLineItems}
                      setRoundLoader={setRoundLoader}
                      setSnackbarMSG={setSnackbarMSG}
                      setSnackbarSeverity={setSnackbarSeverity}
                      setOpenCustSnackbar={setOpenCustSnackbar}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          )}

          <Grid item xs={12} md={12} xl={12} sx={{ mt: 2 }}>
            {load ? (
              <ProgressBar />
            ) : (
              <>
                {planObject.length === 0 ? (
                  <EmptyEstimatesDialog
                    heading="Empty Plans"
                    msg="You don't have any Plans here yet.
                Click the 'ADD' button above to add new Plan"
                  />
                ) : (
                  <Paper className={classes.paper} elevation={0}>
                    <Webviewer
                      estimateId={estimateId}
                      autoSelectPlanName={autoSelectPlanName}
                      setInstance={setInstance}
                      setSnackbarMSG={setSnackbarMSG}
                      setOpenCustSnackbar={setOpenCustSnackbar}
                      setSnackbarSeverity={setSnackbarSeverity}
                      setWantToCallUseEffect={setWantToCallUseEffect}
                      setNewMeasurmentUpdated={setNewMeasurmentUpdated}
                      setDeletedMeasurement={setDeletedMeasurement}
                    />
                    <Dialog
                      open={showConfirm}
                      onClose={handleSubtract}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">{"Confirm Action"}</DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          Are you adding or subtracting this measurement?
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleAdd} color="primary" autoFocus>
                          Add
                        </Button>
                        <Button onClick={handleSubtract} color="primary" autoFocus>
                          Sub
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </Paper>
                )}
              </>
            )}
          </Grid>
          <div style={{ marginTop: "5px", marginRight: "18px" }}>
            <Drawer
              anchor={"right"}
              open={state["right"]}
              onClose={toggleDrawer("left", false)}
              PaperProps={{
                sx: {
                  width: "30%",
                },
              }}
            >
              <div className={classes.Drawerroot}>
                <form onSubmit={handleSubmit(submitNewPlan)}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      {isNewPlanButton ? (
                        <Typography variant="h5" color="primary" style={{ paddingBottom: 6 }}>
                          New Plan
                        </Typography>
                      ) : (
                        <Typography variant="h5" color="primary" style={{ paddingBottom: 6 }}>
                          Update Plan
                        </Typography>
                      )}

                      <Divider />
                    </Grid>

                    <Grid item xs={12}>
                      {isNewPlanButton ? (
                        <Box component="div" my={2}>
                          <Controller
                            name="planName"
                            control={control}
                            rules={{ required: true }}
                            className="input"
                            render={({ field }) => (
                              <TextField
                                {...field}
                                variant="outlined"
                                label="Select Plan"
                                size="small"
                                fullWidth
                              />
                            )}
                          />

                          <p className={ErrorClasses.errorMsg}>
                            {errors.planName?.type === "required" && "Plan name is required"}
                          </p>
                        </Box>
                      ) : (
                        <Box component="div" my={2}>
                          <Controller
                            name="planName"
                            control={control}
                            rules={{ required: true }}
                            className="input"
                            render={({ field }) => (
                              <TextField
                                {...field}
                                disabled
                                variant="outlined"
                                label="Plan Name"
                                size="small"
                                fullWidth
                              />
                            )}
                          />
                        </Box>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <StyledDropZone setPlanFile={setPlanFile} setFileName={setFileName} />
                    </Grid>

                    <Grid item xs={12} align="right">
                      {isNewPlanButton ? (
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          type="submit"
                          className={classes.button}
                          style={{
                            marginLeft: "8px",
                          }}
                        >
                          Save
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          type="submit"
                          className={classes.button}
                          style={{
                            marginLeft: "8px",
                          }}
                        >
                          Update
                        </Button>
                      )}

                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        className={classes.button}
                        onClick={handleClose}
                        style={{
                          marginLeft: "8px",
                        }}
                      >
                        Close
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </div>
            </Drawer>
          </div>
        </Grid>
      )}
      {openCustSnackbar && (
        <CustomizedSnackbars
          openSnackbar={openCustSnackbar}
          setOpenSnackbar={setOpenCustSnackbar}
          snackbarMSG={snackbarMSG}
          severity={snackbarSeverity}
        />
      )}
    </React.Fragment>
  );
}
