/** @format */

import apis from "../../../utils/apis";

//get building lineitems
export const getJobPlanByCostCentreId = (setRows, costCentreId, setLoadResponse) => {
  setLoadResponse(true);
  apis
    .GET({
      endpoint: `/${costCentreId}/job-plans`,
    })
    .then((res) => {
      setLoadResponse(false);

      setRows(res.data);

      console.log("building rows ", res.data);
    })
    .catch((err) => console.log("No building data "));
};
export const createJobPlan = (
  obj,
  setLoadResponse,
  wantToCallGetJobPlan,
  setWantToCallGetJobPlan,
  setSnackbarSeverity,
  setSnackbarMSG,
  setOpenSnackbar,
  setRoundLoader,
  setState
) => {
  setLoadResponse(true);
  setRoundLoader(true);
  apis
    .POST({
      endpoint: `/job-plan`,
      payload: obj,
      options: {
        type: "json",
      },
    })
    .then((res) => {
      if (res.status === 200) {
        setLoadResponse(false);
        setSnackbarMSG("Job Plan created successfully!");
        setSnackbarSeverity("success");
        if (wantToCallGetJobPlan) {
          setWantToCallGetJobPlan(false);
        } else {
          setWantToCallGetJobPlan(true);
        }
        setRoundLoader(false);
        setState({ ["right"]: false });
        setOpenSnackbar(true);
      } else {
        setSnackbarMSG(res?.data?.errorMessage || "Job Plan creation failed!");
        setSnackbarSeverity("error");
        setRoundLoader(false);
        setOpenSnackbar(true);
      }
    })
    .catch((err) => console.log("No data "));
};
export const updateJobPlanGroupByJobPlanId = (
  setRows,
  jobPlanId,
  arr,
  setSnackbarSeverity,
  setSnackbarMSG,
  setOpenSnackbar,
  setTempGroups,
  setRoundLoader
) => {
  setRoundLoader(true);
  apis
    .PUT({
      endpoint: `/job-plan/${jobPlanId}/items`,
      payload: arr,
      options: {
        type: "json",
      },
    })
    .then((res) => {
      if (res.data.message == "SUCCESS") {
        setRows(arr);
        setTempGroups(JSON.parse(JSON.stringify(arr)));
        setSnackbarMSG("Groups updated successfully!");
        setSnackbarSeverity("success");
        setRoundLoader(false);
        setOpenSnackbar(true);
        console.log("building data posting success >>>", arr);
      } else {
        setSnackbarMSG("Groups updation failed!");
        setSnackbarSeverity("error");
        setRoundLoader(false);
        setOpenSnackbar(true);
        console.log("building posting failed>>>");
      }
    });
};

export const getJobPlanItemByJobPlanId = (
  setRows,
  jobPlanId,
  setLoad,
  setTempRows,
  setTempGroups,
  setOpenAllRows
) => {
  setLoad(true);
  apis
    .GET({
      endpoint: `/job-plan/${jobPlanId}/items`,
    })
    .then((res) => {
      var tempOpenRows = [];
      for (var i = 0; i < res.data.length; i++) {
        tempOpenRows[i] = true;
      }
      setOpenAllRows(tempOpenRows);
      setLoad(false);

      res.data.sort(function (a, b) {
        return a.groupCode - b.groupCode;
      });
      res.data.map((groupRow) => {
        groupRow.items.sort(function (a, b) {
          return a.itemCode - b.itemCode;
        });
      });
      setRows(res.data);
      setTempRows(res.data);
      setTempGroups(JSON.parse(JSON.stringify(res.data)));
      console.log("building rows ", res.data);
    })
    .catch((err) => console.log("No building data "));
};

export const updateJobPlanItemByEstimateId = (
  estimateId,
  arr,
  wantToCallGetJobPlan,
  setWantToCallGetJobPlan,
  setOpen,
  setSnackbarSeverity,
  setSnackbarMSG
) => {
  console.log("len in api ", arr);
  setOpen(false);
  // setWantToCallGetJobPlan(true);
  apis
    .PUT({
      endpoint: `/estimate/line-items/${estimateId}`,
      payload: arr,
      options: {
        type: "json",
      },
    })
    .then((res) => {
      if (res.data.message == "SUCCESS") {
        if (wantToCallGetJobPlan) {
          setWantToCallGetJobPlan(false);
        } else {
          setWantToCallGetJobPlan(true);
        }
        setSnackbarMSG("Item updated successfully!");
        setSnackbarSeverity("success");
        setOpen(true);
        // if (arr[arr.length - 1].items.length != 0) {
        // }
        console.log("building data posting success >>>", arr);
      } else {
        setSnackbarMSG("Item updation Unsuccessfull!");
        setSnackbarSeverity("error");
        setOpen(true);
        console.log("building posting failed>>>");
      }
    });
};

export const updateJobPlanItemByJobPlanId = (
  jobPlanId,
  arr,
  wantToCallGetJobPlan,
  setWantToCallGetJobPlan,
  setOpen,
  setSnackbarSeverity,
  setSnackbarMSG
) => {
  console.log("len in api ", arr);
  setOpen(false);
  // setWantToCallGetJobPlan(true);
  apis
    .PUT({
      endpoint: `/job-plan/${jobPlanId}/items`,
      payload: arr,
      options: {
        type: "json",
      },
    })
    .then((res) => {
      if (res.data.message == "SUCCESS") {
        if (wantToCallGetJobPlan) {
          setWantToCallGetJobPlan(false);
        } else {
          setWantToCallGetJobPlan(true);
        }
        setSnackbarMSG("Item updated successfully!");
        setSnackbarSeverity("success");
        setOpen(true);
        // if (arr[arr.length - 1].items.length != 0) {
        // }
        console.log("building data posting success >>>", arr);
      } else {
        setSnackbarMSG("Item updation Unsuccessfull!");
        setSnackbarSeverity("error");
        setOpen(true);
        console.log("building posting failed>>>");
      }
    });
};

export const deleteJobPlabyID = (
  jobPlanId,
  setDelLoading,
  setOpenSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity
) => {
  setDelLoading(true);
  setOpenSnackbar(false);
  apis
    .DELETE({
      endpoint: `/job-plan/${jobPlanId}`,
    })
    .then((res) => {
      setDelLoading(false);
      setOpenSnackbar(true);
      setSnackbarMSG("Job Plan deleted successfully!");
      setSnackbarSeverity("success");
      console.log("deleted Success");
    });
};

/* for get oldData */
export const getJobPlanItem = (setOldData, jobPlanId) => {
  apis
    .GET({
      endpoint: `/job-plan/${jobPlanId}/items`,
    })
    .then((res) => {
      res.data.sort(function (a, b) {
        return a.groupCode - b.groupCode;
      });

      setOldData(res.data);
    })
    .catch((err) => console.log("No building data "));
};

/* copy of update api for remove loader */
export const updateJobPlanItemByJobId = (
  jobPlanId,
  arr,
  setTempGroups,
  setRoundLoader,
  setOpenSnackbar,
  setSnackbarSeverity,
  setSnackbarMSG
) => {
  setRoundLoader(true);
  apis
    .PUT({
      endpoint: `/job-plan/${jobPlanId}/items`,
      payload: arr,
      options: {
        type: "json",
      },
    })
    .then((res) => {
      if (res) {
        setTempGroups(res.data);
        setSnackbarSeverity("success");
        setSnackbarMSG("Line items saved succesfully!");
        setRoundLoader(false);
        setOpenSnackbar(true);
        console.log("building data posting success >>>", arr);
        // if (arr[arr.length - 1].items.length != 0) {
        //   setDataPostSuccess(true);
        // }
        setTempGroups(JSON.parse(JSON.stringify(arr)));
        console.log("building data posting success >>>", arr);
      } else {
        setSnackbarSeverity("error");
        setSnackbarMSG("Line items fail to save");
        setRoundLoader(false);
        setOpenSnackbar(true);
        console.log("building posting failed>>>");
      }
    });
};

/*  its for estimate costing */
export const getJobPlanItemByEstimateJobPlanId = (
  setRows,
  estimateId,
  setLoad,
  setTempRows,
  setTempGroups,
  setOpenAllRows
) => {
  apis
    .GET({
      endpoint: `/estimate/line-items/${estimateId}`,
    })
    .then((res) => {
      var tempOpenRows = [];
      for (var i = 0; i < res.data.length; i++) {
        tempOpenRows[i] = true;
      }
      setOpenAllRows(tempOpenRows);
      setLoad(false);
      res.data.sort(function (a, b) {
        return a.groupCode - b.groupCode;
      });
      res.data.map((groupRow) => {
        groupRow.items.sort(function (a, b) {
          return a.itemCode - b.itemCode;
        });
      });
      setRows(res.data);
      setTempRows(res.data);
      setTempGroups(JSON.parse(JSON.stringify(res.data)));
      setLoad(false);
    })
    .catch((err) => console.log("No building data "));
};
export const getEstimateLineItemsWithGroup = (setRows, estimateId) => {
  apis
    .GET({
      endpoint: `/estimate/line-items/${estimateId}`,
    })
    .then((res) => {
      setRows(res.data);
    })
    .catch((err) => console.log("No building data "));
};
export const updateJobPlanGroupByEstimateId = (
  setRows,
  estimateId,
  arr,
  setSnackbarSeverity,
  setSnackbarMSG,
  setOpen,
  setTempGroups,
  setRoundLoader
) => {
  setRoundLoader(true);
  apis
    .PUT({
      endpoint: `/estimate/line-items/${estimateId}`,
      payload: arr,
      options: {
        type: "json",
      },
    })
    .then((res) => {
      if (res) {
        setRows(arr);
        setTempGroups(JSON.parse(JSON.stringify(arr)));
        setSnackbarMSG("Group deleted successfully!");
        setSnackbarSeverity("success");
        setRoundLoader(false);
        setOpen(true);
        console.log("building data posting success >>>", arr);
      } else {
        setSnackbarMSG("Group delation Unsuccessfull!");
        setSnackbarSeverity("error");
        setRoundLoader(false);
        setOpen(true);
        console.log("building posting failed>>>");
      }
    });
};

export const updateJobPlanItemByEstimateJobPlanId = (
  setRows,
  estimateId,
  arr,
  setLoad,
  setOpen,
  setDataPostSuccess,
  setSnackbarSeverity,
  setSnackbarMSG,
  setTempGroups,
  setRoundLoader
) => {
  console.log("len in api ", arr);
  setLoad(true);
  setRoundLoader(true);
  apis
    .PUT({
      endpoint: `/estimate/line-items/${estimateId}`,
      payload: arr,
      options: {
        type: "json",
      },
    })
    .then((res) => {
      setLoad(false);
      setRows(arr);
      if (res) {
        setTempGroups(JSON.parse(JSON.stringify(arr)));
        if (arr[arr.length - 1].items.length != 0) {
          setDataPostSuccess(true);
        }
        setSnackbarMSG("Costing Updated Successfully!");
        setSnackbarSeverity("success");
        setRoundLoader(false);
        setOpen(true);
        console.log("building data posting success >>>", arr);
      } else {
        setSnackbarMSG("Costing updation failed!");
        setSnackbarSeverity("error");
        setRoundLoader(false);
        setOpen(true);
        console.log("building posting failed>>>");
      }
    });
};

export const tempEstimate = (
  estimateId,
  arr,
  setRows,
  setRoundLoader,
  setOpenSnackbar,
  setSnackbarSeverity,
  setSnackbarMSG
) => {
  console.log("len in api ", arr);
  setRoundLoader(true);
  apis
    .PUT({
      endpoint: `/estimate/line-items/${estimateId}`,
      payload: arr,
      options: {
        type: "json",
      },
    })
    .then((res) => {
      if (res) {
        setRows(res.data);
        setSnackbarSeverity("success");
        setSnackbarMSG("Line items saved succesfully!");
        setRoundLoader(false);
        setOpenSnackbar(true);
        console.log("building data posting success >>>", arr);
      } else {
        setSnackbarSeverity("error");
        setSnackbarMSG("Line items fail to save");
        setRoundLoader(false);
        setOpenSnackbar(true);
        console.log("building posting failed>>>");
      }

      // getUpdatedEstimateData(estimateId, setRows);
    });
};
//short function

export const updateJobPlanItemsByEstimateId = (
  estimateId,
  arr
  //setcallGetEstimatePlan
) => {
  console.log("len in api ", arr);

  apis
    .PUT({
      endpoint: `/estimate/line-items/${estimateId}`,
      payload: arr,
      options: {
        type: "json",
      },
    })
    .then((res) => {
      if (res.data.message == "SUCCESS") {
        //setcallGetEstimatePlan(true);
        // if (arr[arr.length - 1].items.length != 0) {
        //   setDataPostSuccess(true);
        // }
        console.log("building data posting success >>>", arr);
      } else {
        console.log("building posting failed>>>");
      }
    });
};

export const getEstimateJobPlanItem = (setOldData, estimateId) => {
  apis
    .GET({
      endpoint: `/estimate/line-items/${estimateId}`,
    })
    .then((res) => {
      res.data.sort(function (a, b) {
        return a.groupCode - b.groupCode;
      });
      console.log("res", res);
      setOldData(res.data);
    })
    .catch((err) => console.log("No building data "));
};

export const getJobPlanInCostCentreJobPlan = (setJobPlanName, jobPlanId, setJobPlanData) => {
  apis
    .GET({
      endpoint: "/job-plans",
    })
    .then((res) => {
      var name = res.data.filter((item) => {
        if (item.jobPlanId === jobPlanId) {
          setJobPlanName(item.jobPlanName);
          // console.log("obj---",item)
          setJobPlanData(item);
        }
      });
      // setLoad(false);
    });
};

export const getUpdatedEstimateData = (estimateId, setRows) => {
  apis
    .GET({
      endpoint: `/estimate/line-items/${estimateId}`,
    })
    .then((res) => {
      console.log("building rows ", res);

      res.data.sort(function (a, b) {
        return a.groupCode - b.groupCode;
      });
      setRows(res.data);
      console.log("building rows ", res.data);
    });
};
