/** @format */

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import {
  Autocomplete,
  Button,
  Divider,
  Drawer,
  FormGroup,
  Snackbar,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
  styled,
  TableCell,
  TableRow,
  TableHead,
} from "@mui/material";
import CreateCosting from "../../estimates/EstimateCosting/CreateCosting";
import ProgressBar from "../../UI/ProgressBar";
import Grid from "@mui/material/Grid";
import {
  getEstimateLineItemsWithGroup,
  getJobPlanItemByEstimateJobPlanId,
  getJobPlanItemByJobPlanId,
  updateJobPlanGroupByJobPlanId,
  updateJobPlanItemByEstimateId,
  updateJobPlanItemByEstimateJobPlanId,
  updateJobPlanItemByJobPlanId,
} from "./jobplan.helpers";
import { getEstimatesbyID } from "../../estimates/estimates.helpers";
import Quoterequests from "../../quotes/quoterequests/Quoterequests";
import Row from "./Row";
import HeaderTotal from "../../estimates/EstimateCosting/HeaderTotal";
import EmptyEstimatesDialog from "../../UI/EmptyEstimatesDialog";
import CustomizedSnackbars from "../../UI/CustomizedSnackbars";
import { uuid } from "uuidv4";
import NumberFormat from "react-number-format";
import { getAllCatalogueItems, getCatalogue } from "../../catalogue/catalogue.helper";
import CreateCatalogueFromJobplanItem from "./CreateCatalogueFromJobplanItem";
import { getUserConfigsTypes, getUserConfigsUom } from "../../UI/GlobalApi";
import { ButtonGroup } from "@mui/material";
import { CSVBoxButton } from "@csvbox/react";
import { makeStyles } from "@mui/styles";
import SimpleBackdrop from "../../UI/SimpleBackdrop";
import ErrorDialog from "../../UI/ErrorDialog";
import { isJobplanEditable } from "../../jobPlans/jobplans.helpers";
import Title from "../../UI/Title";
import Webviewer from "../../estimates/TakeoffPlans/Webviewer";
import MUIDataTable from "mui-datatables";
import LineItemsTableForPlans from "../../estimates/TakeoffPlans/LineItemsTableForPlans";
import { getEstimateLineItemsList } from "../../estimates/EstimateCosting/estimate.costings";
import { getFileByUSingUrl } from "../../estimates/TakeoffPlans/takeoff.helpers";
const useStyles = makeStyles({
  fullList: {
    width: "auto",
    padding: 10,
  },
  button: {
    whiteSpace: "nowrap",
  },
  Drawerroot: {
    width: "100%",
    padding: 16,
  },
});
export default function JobPlanItemsRow(props) {
  var { jobPlanId } = useParams();
  var { estimateId } = useParams();
  const { editableJobplan, estimateStatus } = props;
  // const { userConfigs } = props;
  // const { userConfigsuom } = props;
  const classes = useStyles();

  var title = "Job Plan Items";
  const [openCreate, setOpenCreate] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [groupCode, setGroupCode] = useState("");
  const [items, setItems] = useState([]);
  const [rows, setRows] = React.useState([]);
  const [tempRows, setTempRows] = React.useState([]);
  const [id, setId] = useState(rows.length);
  const [load, setLoad] = useState(true);
  const [open, setOpen] = useState(false);
  const [openAllRows, setOpenAllRows] = useState([]);
  const [loadSave, setLoadSave] = useState(false);
  const [disable, setDisable] = React.useState(true);
  const [isEdit, setEdit] = React.useState(false);
  const [disableSave, setDisableSave] = React.useState(true);
  const [isDataPostSuccess, setDataPostSuccess] = React.useState(false);
  const [openedItemRow, setOpenedItemRow] = React.useState({});
  const [wantToCallGetJobPlan, setWantToCallGetJobPlan] = useState(true);
  const [userConfigs, setUserConfigs] = React.useState({});
  const [userConfigsuom, setUserConfigsuom] = React.useState({});
  const [snackbarMSG, setSnackbarMSG] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("");
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [checkedJobPlanLineItems, setCheckedJobPlanLineItems] = useState([]);
  const [checkedJobPlanLineItemsForPlans, setCheckedJobPlanLineItemsForPlans] = useState([]);
  // var unique_id = uuid();
  const [allCatalogue, setAllCatalogue] = React.useState([]);
  const [catalogueItems, setCatalogueItems] = React.useState([]);
  const [importedData, setImportedData] = useState([]);
  const [tempGroups, setTempGroups] = useState([]);
  const [isImported, setIsImported] = useState(false);
  const [roundLoader, setRoundLoader] = useState(false);
  const [openErrDialog, setOpenErrDialog] = useState(false);
  const [errorMsg, setErrorMsg] = useState("There is an error occured in your server");
  const [errorCode, setErrorCode] = useState("Internal Server Error");
  const [expandRows, setExpandRows] = useState(false);
  const [planDrawerOpen, setPlanDrawerOpen] = useState(false);
  const [planObject, setPlanObject] = React.useState([]);
  const [autoSelectPlanName, setAutoSelectPlanName] = useState(null);
  const [instance, setInstance] = useState();
  const [isAdd, setIsAdd] = React.useState(true);
  const [addMeasurementData, setAddMeasurementData] = useState([]);
  const [subMeasurementData, setSubMeasurementData] = useState([]);
  const [addMeasurementTotal, setAddMeasurementTotal] = useState(0);
  const [subMeasurementTotal, setSubMeasurementTotal] = useState(0);
  const [lineItemsList, setLineItemsList] = useState([]);
  const [lineItemLoad, setLineItemLoad] = useState(true);
  const [finalMeasurement, setFinalMeasurement] = useState(0);
  const [estimateLineItems, setEstimateLineItems] = useState([]);
  const [wantToCallUseEffect, setWantToCallUseEffect] = useState(true);
  const [newMeasurmentUpdated, setNewMeasurmentUpdated] = React.useState({});
  const [planName, setPlanName] = useState("");
  const [deletedMeasurement, setDeletedMeasurement] = useState(0);
  const [allMeasurements, setAllMeasurements] = useState([]);

  useEffect(() => {
    if (props.type === "jobPlan") {
      getJobPlanItemByJobPlanId(
        setRows,
        jobPlanId,
        setLoad,
        setTempRows,
        setTempGroups,
        setOpenAllRows
      );
    } else if (props.type === "estimate") {
      // getEstimatesbyID(estimateId, setEstimateData); //get estimates
      getJobPlanItemByEstimateJobPlanId(
        setRows,
        estimateId,
        setLoad,
        setTempRows,
        setTempGroups,
        setOpenAllRows
      );
    }
  }, [wantToCallGetJobPlan]);
  var tempOpenRows = [];
  const handleOpenAllRows = () => {
    for (var i = 0; i < rows.length; i++) {
      tempOpenRows[i] = true;
    }
    console.log("tempOpenRows:", tempOpenRows);
    setOpenAllRows(tempOpenRows);
  };

  const handleSave = () => {
    //Code to generate group code automatically by sequence
    // if (rows.length === 0) {
    //   var lastBeforeRowSeqId = 0;
    // } else {
    //   var lastBeforeRowSeqId = rows[rows.length - 1].groupCode;
    // }
    // -----------------------------------------------------------
    setId(id + 1);

    let arrOfObj = {
      groupCode: groupCode,
      groupName: groupName,
      items: items,
    };
    let arr = [...rows, arrOfObj];

    if (props.type === "estimate") {
      updateJobPlanItemByEstimateJobPlanId(
        setRows,
        estimateId,
        arr,
        setLoadSave,
        setOpenSnackbar,
        setDataPostSuccess,
        setSnackbarSeverity,
        setSnackbarMSG,
        setTempGroups,
        setRoundLoader
      );
    } else if (props.type === "jobPlan") {
      updateJobPlanGroupByJobPlanId(
        setRows,
        jobPlanId,
        arr,
        setSnackbarSeverity,
        setSnackbarMSG,
        setOpenSnackbar,
        setTempGroups,
        setRoundLoader
      );
    }
    setDisable(true);
    setGroupName("");
    setOpenCreate(!openCreate);
  };

  useEffect(() => {
    getUserConfigsTypes(setUserConfigs); //get all configs Types
    getUserConfigsUom(setUserConfigsuom, setDummyLoad); //get all configs uom
    getAllCatalogueItems(setCatalogueItems);
    getCatalogue(setAllCatalogue, setDummyLoad);
  }, []);

  // var nameArray=

  const [dummyLoad, setDummyLoad] = useState(true); //its a only for dummy

  const handleCancelOuter = () => {
    if (props.type === "jobPlan") {
      getJobPlanItemByJobPlanId(setRows, jobPlanId, setDummyLoad, setTempRows);

      console.log("if ");
    } else if (props.type === "estimate") {
      getJobPlanItemByEstimateJobPlanId(setRows, estimateId, setDummyLoad, setTempRows);
    }

    setDisableSave(true);
    setOpen(false);
    setEdit(false);
    handleCloseAllRows();
  };
  const [quote, setQuote] = useState();

  const handleSaveOuter = () => {
    console.log("tempRows-------", tempRows);
    console.log("rows-------", rows);

    if (props.type === "estimate") {
      console.log("if estimate");
      updateJobPlanItemByEstimateId(
        estimateId,
        rows,
        wantToCallGetJobPlan,
        setWantToCallGetJobPlan,
        setOpenSnackbar,
        setSnackbarSeverity,
        setSnackbarMSG
      );
    } else if (props.type === "jobPlan") {
      updateJobPlanItemByJobPlanId(
        jobPlanId,
        rows,
        wantToCallGetJobPlan,
        setWantToCallGetJobPlan,
        setOpenSnackbar,
        setSnackbarSeverity,
        setSnackbarMSG
      );
    }
    setEdit(false);
    setDisableSave(true);
    handleCloseAllRows();
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleCloseAllRows = () => {
    for (var i = 0; i < rows.length; i++) {
      tempOpenRows[i] = false;
    }
    setCreateQuote("");

    setOpenAllRows(tempOpenRows);
  };

  const [createQuote, setCreateQuote] = useState([]);
  const handleAddQuote = (e, data) => {
    var row;
    if (data.itemId === null) {
      tempRows.filter((el) => {
        if (el.groupCode == data.itemGroupCode && el.groupName == data.itemGroupName) {
          el.items.filter((element) => {
            if (element.optionId === data.optionId) {
              row = element;
            }
          });
        }
      });
    } else {
      row = data;
    }
    var Quotelistfilter = [];
    let Quotelist = [];
    if (e.target.checked) {
      console.log("e.value", row.lineItemId);

      Quotelist = [...createQuote];
      // id: row.itemId,
      Quotelist.push({
        estimateLineItemId: row.lineItemId,
        description: row.itemDescription,
        type: row.type,
        qty: row.qty,
        uom: row.uom,
      });
      Quotelistfilter = Quotelist.filter(function (el) {
        return el.estimateLineItemId != null;
      });

      console.log("Quotelistfilter ", Quotelistfilter);
    } else {
      Quotelist = [...createQuote];
      Quotelistfilter = Quotelist.filter(function (el) {
        return el.estimateLineItemId !== row.lineItemId && el.estimateLineItemId !== null;
      });
      console.log("QuoteList else", Quotelist);
    }
    setCreateQuote(Quotelistfilter);
    console.log("createQuote", createQuote);
  };

  // ---------------------------------------------------------------------
  const handleAddToCatalogue = (e, row) => {
    console.log(" JobLineItem:", row);
    let { value } = e.target;
    let jobPlanLineItems = [];
    let filteredData = [];
    if (e.target.checked) {
      jobPlanLineItems = [...checkedJobPlanLineItems];
      jobPlanLineItems.push({
        itemId: row.itemId,
        itemCode: row.itemCode,
        itemName: row.itemName,
        itemGroupCode: row.itemGroupCode,
        itemGroupName: row.itemGroupName,
        itemDescription: row.itemDescription,
        itemCategory: row.itemCategory,
        type: row.type,
        qty: row.qty,
        uom: row.uom,
        unitCost: row.unitCost,
        markupPercentage: row.markupPercentage,
        gst: row.gst,
      });
      setCheckedJobPlanLineItems(jobPlanLineItems);
    } else {
      console.log("Unchecked");
      filteredData = checkedJobPlanLineItems.filter((ele) => {
        return ele.itemId !== row.itemId;
      });
      console.log("Unchecked Filter", filteredData);
      setCheckedJobPlanLineItems(filteredData);
    }
  };
  const handleAddToPlans = (e, row) => {
    let jobPlanLineItems = [];
    let filteredData = [];
    if (e.target.checked) {
      jobPlanLineItems = [...checkedJobPlanLineItemsForPlans];
      jobPlanLineItems.push(row);
      setCheckedJobPlanLineItemsForPlans(jobPlanLineItems);
    } else {
      filteredData = checkedJobPlanLineItems.filter((ele) => {
        return ele.itemId !== row.itemId;
      });
      setCheckedJobPlanLineItemsForPlans(filteredData);
    }
  };
  // --------------------------UseEffect-For-Import-Data----------------------------------------------
  useEffect(() => {
    if (importedData.length > 0) {
      let result;
      console.log("importedData", importedData);
      //below code is to generate groupCode automatically
      // var lastItemId;
      // var lastBeforeRowSeqId;

      // if (rows.length === 0) {
      //   lastBeforeRowSeqId = 0;
      // } else {
      //   lastBeforeRowSeqId = rows[rows.length - 1].groupCode;
      // }

      let newLineItemsWithGrp = importedData
        .filter(function (item) {
          var index = rows.findIndex((g) => g.groupName == item["group_name"]);
          if (index >= 0) {
            //return item if it is matching group
            return item;
          } else {
            //If group is not matched then create new group with empty items
            rows.push({
              groupCode: item["group_code"],
              groupName: item["group_name"],
              items: [],
            });
            return item;
          }
        })
        .map(function (item) {
          //This will add imported item in matching group
          var index = rows.findIndex((g) => g.groupName == item["group_name"]);
          // if (rows[index].items.length === 0) {
          //   lastItemId = 0;
          // } else {
          //   lastItemId = rows[index].items[rows[index].items.length - 1].itemCode;
          // }

          result = rows[index].items.findIndex((rowItem) => {
            return rowItem.itemCode === item["code"];
          });

          if (result !== -1) {
            rows[index].items[result] = {
              itemId: null,
              itemCode: item["code"],
              itemName: item["name"],
              itemGroupCode: rows[index].groupCode,
              itemGroupName: rows[index].groupName,
              itemDescription: item["description"],
              itemCategory: rows[index].groupName,
              optionId: uuid(),
              optionName: "STANDARD",
              type: item.type,
              qty: item.qty,
              uom: item.uom,
              unitCost: item["unit_cost"],
              markupPercentage: item.markup,
              gst: item.gst,
              included: true,
              priceOptions: [
                {
                  optionId: uuid(),
                  optionName: "STANDARD",
                  itemDescription: "",
                  type: "",
                  qty: 1,
                  uom: "",
                  unitCost: 0,
                  markupPercentage: 0,
                  gst: true,
                  included: true,
                },
              ],
            };
          } else {
            rows[index].items.push({
              itemId: null,
              itemCode: item["code"],
              itemName: item["name"],
              itemGroupCode: rows[index].groupCode,
              itemGroupName: rows[index].groupName,
              itemDescription: item["description"],
              itemCategory: rows[index].groupName,
              optionId: uuid(),
              optionName: "STANDARD",
              type: item.type,
              qty: item.qty,
              uom: item.uom,
              unitCost: item["unit_cost"],
              markupPercentage: item.markup,
              gst: item.gst,
              included: true,
              priceOptions: [
                {
                  optionId: uuid(),
                  optionName: "STANDARD",
                  itemDescription: "",
                  type: "",
                  qty: 1,
                  uom: "",
                  unitCost: 0,
                  markupPercentage: 0,
                  gst: true,
                  included: true,
                },
              ],
            });
          }
        });
      newLineItemsWithGrp = [];
      setIsImported(true);
    }
  }, [importedData]);
  // ------------------------------------------------------------------------
  const handleImportedDataSave = () => {
    setIsImported(false);
    if (props.type === "estimate") {
      console.log("if estimate");
      updateJobPlanItemByEstimateId(
        estimateId,
        rows,
        wantToCallGetJobPlan,
        setWantToCallGetJobPlan,
        setOpenSnackbar,
        setSnackbarSeverity,
        setSnackbarMSG
      );
    } else if (props.type === "jobPlan") {
      updateJobPlanItemByJobPlanId(
        jobPlanId,
        rows,
        wantToCallGetJobPlan,
        setWantToCallGetJobPlan,
        setOpenSnackbar,
        setSnackbarSeverity,
        setSnackbarMSG
      );
    }
  };
  // -----------------------------------------------------------s-------------
  const handleImportedDataDiscard = () => {
    setRows(JSON.parse(JSON.stringify(tempGroups)));
    setIsImported(false);
    setImportedData([]);
  };
  // ------------------------------------------------------------------------
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.mode === "dark" ? "rgba(255,255,255,.35)" : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));
  // -----------------------------------------------------------------------
  const columnsAddMeasurment = [
    {
      name: "measurment",
      label: "Measurement",
      options: {
        filter: true,
        sort: true,
        display: true,
        // setCellProps: () => ({ style: { minWidth: "800px", maxWidth: "800px" } }),
      },
    },
  ];
  const columnsDeleteMeasurment = [
    {
      name: "measurment",
      label: "Measurement",
      options: {
        filter: true,
        sort: true,
        display: true,
        // setCellProps: () => ({ style: { minWidth: "800px", maxWidth: "800px" } }),
      },
    },
  ];
  // -----------------------------------------------------------------------

  const addOptions = {
    tableBodyHeight: "auto",
    selectableRowsOnClick: true,
    responsive: "standard",
    rowsPerPageOptions: [10, 15, 20],
    rowsPerPage: "10",
    selectableRows: false,
    filter: false,
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    selectableRowsOnClick: false,
    selectableRows: "none",
    expandableRows: false,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    customFooter: () => {
      return <div>Total:{addMeasurementTotal} </div>;
    },
    textLabels: {
      pagination: {
        next: "Next >",
        previous: "< Previous",
        rowsPerPage: "Total items Per Page",
        displayRows: "OF",
      },
    },
  };
  const subOptions = {
    tableBodyHeight: "auto",
    selectableRowsOnClick: true,
    responsive: "standard",
    rowsPerPageOptions: [10, 15, 20],
    rowsPerPage: "10",
    selectableRows: false,
    filter: false,
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    selectableRowsOnClick: false,
    selectableRows: "none",
    expandableRows: false,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    customFooter: () => {
      return <div>Total:{subMeasurementTotal} </div>;
    },
    textLabels: {
      pagination: {
        next: "Next >",
        previous: "< Previous",
        rowsPerPage: "Total items Per Page",
        displayRows: "OF",
      },
    },
  };
  // -----------------------------------------------------------------------
  useEffect(() => {
    if (props.type === "estimate") {
      getEstimateLineItemsList(setLineItemsList, estimateId, setLineItemLoad);
      getEstimateLineItemsWithGroup(setEstimateLineItems, estimateId);
    }
  }, []);
  // -----------------------------------------------------------------------
  useEffect(() => {
    let total = 0;
    addMeasurementData?.map((item) => {
      total = total + item.measurment;
    });
    setAddMeasurementTotal(total);
  }, [addMeasurementData]);
  // -------------------------------------------------

  useEffect(() => {
    let total = 0;
    if (addMeasurementTotal > subMeasurementTotal) {
      total = addMeasurementTotal - subMeasurementTotal;
    } else {
      total = subMeasurementTotal - addMeasurementTotal;
    }
    setFinalMeasurement(total);
  }, [addMeasurementTotal, subMeasurementTotal]);
  // -------------------------------------------------

  useEffect(() => {
    let total = 0;
    subMeasurementData.map((item) => (total = total + item.measurment));
    setSubMeasurementTotal(total);
  }, [subMeasurementData]);
  // -------------------------------------------------
  useEffect(() => {
    if (Object.keys(newMeasurmentUpdated).length !== 0) {
      setAllMeasurements((current) => [...current, { ...newMeasurmentUpdated, checked: true }]);
      // if (isAdd) {
      //   setAddMeasurementData((current) => [...current, newMeasurmentUpdated]);
      // } else {
      //   setSubMeasurementData((current) => [...current, newMeasurmentUpdated]);
      // }
    }
  }, [newMeasurmentUpdated]);
  // -------------------------------------------------
  useEffect(() => {
    console.log("deletedMeasurement type", typeof deletedMeasurement);

    let addData = addMeasurementData.filter((item) => {
      console.log("typeof Item", typeof item.measurment);

      return item.measurment !== deletedMeasurement;
    });
    let subData = subMeasurementData.filter((item) => item.measurment !== deletedMeasurement);
    console.log("addData", addData);
    setAddMeasurementData(addData);
    setSubMeasurementData(subData);
  }, [deletedMeasurement]);
  // -------------------------------------------------

  useEffect(() => {
    if (props.type === "estimate")
      getFileByUSingUrl(
        estimateId,
        instance,
        setPlanObject,
        autoSelectPlanName,
        setAutoSelectPlanName,
        setLoad,
        planName
      );

    return () => {
      setPlanName("");
      setOpenSnackbar(false);
    };
  }, [wantToCallUseEffect]);
  /* ---------------------------------------------------------------------------------- */
  useEffect(() => {
    let sum = 0;
    allMeasurements.forEach((obj) => {
      sum += obj.measurment;
    });
    setFinalMeasurement(sum.toFixed(2));
  }, [allMeasurements]);
  // -------------------------------------------------
  const handleChangeTableToggle = (isChecked, measurement, index) => {
    let tempMeasures = allMeasurements;
    tempMeasures[index] = { ...tempMeasures[index], checked: isChecked };
    setAllMeasurements(tempMeasures);

    isChecked
      ? setFinalMeasurement(finalMeasurement + measurement)
      : setFinalMeasurement(finalMeasurement - measurement);
  };
  /* ---------------------------------------------------------------------------------- */

  return (
    <React.Fragment>
      {roundLoader && <SimpleBackdrop />}
      {planDrawerOpen && (
        <Drawer
          anchor={"right"}
          open={planDrawerOpen}
          // onClose={setPlanDrawerOpen(false)}
          PaperProps={{
            sx: {
              width: "80%",
            },
          }}
        >
          <React.Fragment>
            <div className={classes.Drawerroot}>
              {/* <Typography variant="h6" gutterBottom>Plans</Typography> */}
              <Grid container justifyContent={"space-between"}>
                <Grid item xs={6}>
                  <Title title="Plans" />
                </Grid>
                <Grid item xs={6} sx={{ pt: 2 }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "right",
                    }}
                  >
                    <Button
                      onClick={() => setPlanDrawerOpen(false)}
                      color="error"
                      variant="contained"
                      size="small"
                    >
                      Close
                    </Button>
                  </div>
                </Grid>
              </Grid>

              {load ? (
                <ProgressBar />
              ) : (
                <Grid>
                  <Paper className={classes.paper} elevation={1} sx={{ mt: 2 }}>
                    <Grid container sx={{ p: 2 }} alignItems="center">
                      <Grid item xs={4} md={5} lg={3}>
                        {planObject.length !== 0 && (
                          <Autocomplete
                            options={planObject}
                            disableClearable
                            value={autoSelectPlanName}
                            size="small"
                            id="planName"
                            onChange={(event, newValue) => {
                              setAutoSelectPlanName(newValue);
                              instance.UI.loadDocument(newValue.documentUrl);
                            }}
                            getOptionLabel={(option) => option.planName}
                            renderInput={(params) => (
                              <TextField {...params} variant="outlined" name="planName" />
                            )}
                          />
                        )}
                      </Grid>
                    </Grid>
                    <Grid item xs={12} container spacing={2}>
                      <Grid item xs={12}>
                        <div style={{ minWidth: 275, margin: 7 }}>
                          <Table
                            sx={{
                              borderBottom: "none",
                              justifyContent: "center",
                              overflowX: "auto",
                            }}
                            size="small"
                            aria-label="a dense table"
                            id="estimationCosting"
                            className={" estimationCosting"}
                          >
                            <TableHead>
                              <TableRow>
                                {/* <TableCell align="left" width="15%"></TableCell> */}
                                <TableCell align="center" width="auto">
                                  Measurements
                                </TableCell>
                                <TableCell align="center" width="12%">
                                  Action
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {allMeasurements.map((obj, index) => {
                                return (
                                  <TableRow hover>
                                    <TableCell width="15%" align="center">
                                      {console.log("obj?.measurement:", obj?.measurment)}
                                      {obj?.measurment}
                                    </TableCell>
                                    <TableCell align="center">
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "flex-end",
                                        }}
                                      >
                                        <FormGroup sx={{ mr: 2, mb: 2 }}>
                                          <Stack direction="row" spacing={1} alignItems="center">
                                            <Typography>Subtract</Typography>
                                            <AntSwitch
                                              checked={obj?.checked}
                                              inputProps={{
                                                "aria-label": "ant design",
                                              }}
                                              onChange={(e) =>
                                                handleChangeTableToggle(
                                                  e.target.checked,
                                                  obj?.measurment,
                                                  index
                                                )
                                              }
                                            />
                                            <Typography>Add</Typography>
                                          </Stack>
                                        </FormGroup>
                                      </div>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                              <TableRow hover>
                                <TableCell width="15%" align="center">
                                  Total: {finalMeasurement}
                                </TableCell>
                                <TableCell width="15%" align="center"></TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </div>
                      </Grid>

                      <Grid item xs={12}>
                        {lineItemLoad ? (
                          <ProgressBar />
                        ) : (
                          <LineItemsTableForPlans
                            lineItemsList={lineItemsList}
                            finalMeasurement={finalMeasurement}
                            estimateLineItems={estimateLineItems}
                            setRoundLoader={setRoundLoader}
                            setSnackbarMSG={setSnackbarMSG}
                            setSnackbarSeverity={setSnackbarSeverity}
                            setOpenCustSnackbar={setOpenSnackbar}
                            checkedJobPlanLineItemsForPlans={checkedJobPlanLineItemsForPlans}
                            callFrom={"takeOffBtn"}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Paper>
                  <Grid item xs={12} md={12} xl={12} sx={{ mt: 2 }}>
                    {load ? (
                      <ProgressBar />
                    ) : (
                      <>
                        {planObject.length === 0 ? (
                          <EmptyEstimatesDialog
                            heading="Empty Plans"
                            msg="You don't have any Plans here yet.
                Click the 'ADD' button above to add new Plan"
                          />
                        ) : (
                          <Paper className={classes.paper} elevation={0}>
                            <Webviewer
                              estimateId={estimateId}
                              autoSelectPlanName={autoSelectPlanName}
                              setInstance={setInstance}
                              setSnackbarMSG={setSnackbarMSG}
                              setOpenCustSnackbar={setOpenSnackbar}
                              setSnackbarSeverity={setSnackbarSeverity}
                              setWantToCallUseEffect={setWantToCallUseEffect}
                              setNewMeasurmentUpdated={setNewMeasurmentUpdated}
                              setDeletedMeasurement={setDeletedMeasurement}
                            />
                          </Paper>
                        )}
                      </>
                    )}
                  </Grid>
                </Grid>
              )}
            </div>
          </React.Fragment>
        </Drawer>
      )}
      {load ? ( //if rows are empty then progress bar is shown
        <ProgressBar />
      ) : (
        <Grid container>
          <Grid item xs={12}>
            <Box my={1}>
              <CreateCosting
                category={groupName}
                setCategory={setGroupName}
                groupCode={groupCode}
                setGroupCode={setGroupCode}
                rows={rows}
                setRows={setRows}
                id={id}
                setId={setId}
                lineItems={items}
                jobTypeID={jobPlanId}
                handleSave={handleSave}
                load={loadSave}
                openCreate={openCreate}
                setOpenCreate={setOpenCreate}
                disable={disable}
                setDisable={setDisable}
                title={title}
                textFieldMsg="Add Group"
                handleOpenAllRows={handleOpenAllRows}
                handleCloseAllRows={handleCloseAllRows}
                editable={editableJobplan}
                estimateStatus={estimateStatus}
                type={props.type}
              />
              {/* {loadSave && <SimpleBackdrop />} */}
            </Box>
          </Grid>
          <Grid container xs={12} justifyContent="flex-end">
            <Box mt={2}>
              <ButtonGroup>
                <CSVBoxButton
                  licenseKey="jBoJGQgciXNFmxy20U9fcjOaYCPbnC"
                  user={{
                    user_id: "default123",
                  }}
                  onImport={(result, data) => {
                    if (result) {
                      console.log("result:", result);
                      setImportedData(JSON.parse(JSON.stringify(data.rows)));
                      setSnackbarMSG("Data Imported Succesfully");
                      setSnackbarSeverity("success");
                      setOpenSnackbar(true);
                    } else {
                      console.log("fail");
                    }
                  }}
                  render={(launch) => {
                    return props.type === "jobPlan" ? (
                      <Button
                        data-csvbox
                        onClick={launch}
                        variant="contained"
                        size="small"
                        color="primary"
                        align="left"
                        className={classes.button}
                        disabled={!editableJobplan}
                      >
                        Import
                      </Button>
                    ) : (
                      <Button
                        data-csvbox
                        onClick={launch}
                        variant="contained"
                        size="small"
                        color="primary"
                        align="left"
                        className={classes.button}
                        disabled={isImported || estimateStatus !== "DRAFT"}
                      >
                        Import
                      </Button>
                    );
                  }}
                >
                  Import
                </CSVBoxButton>
                {isImported && (
                  <>
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      onClick={handleImportedDataSave}
                      className={classes.button}
                    >
                      Save
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      color="secondary"
                      onClick={handleImportedDataDiscard}
                      className={classes.button}
                    >
                      Discard
                    </Button>
                  </>
                )}
              </ButtonGroup>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Paper>
              <Box m={1} py={2}>
                {rows.length === 0 ? (
                  <EmptyEstimatesDialog
                    heading="Empty Estimate Groups"
                    msg="You don't have any Estimate Groups here yet. Please create"
                  />
                ) : (
                  <TableContainer>
                    <Table
                      size="small"
                      sx={{
                        display: "block",
                      }}
                      aria-label="collapsible table"
                    >
                      <TableBody
                        sx={{
                          display: "block",
                        }}
                      >
                        {loadSave.length === 0 ? (
                          <ProgressBar />
                        ) : (
                          rows.map((row, index) => (
                            <Row
                              handleChange={props.handleChange}
                              row={row}
                              setItems={setItems}
                              setRows={setRows}
                              rows={rows}
                              jobPlanId={jobPlanId}
                              estimateId={estimateId}
                              loadSave={loadSave}
                              isEdit={isEdit}
                              setEdit={setEdit}
                              setDisableSave={setDisableSave}
                              setTempRows={setTempRows}
                              setOpenedItemRow={setOpenedItemRow}
                              openAllRows={openAllRows}
                              setOpenAllRows={setOpenAllRows}
                              index={index}
                              props={props}
                              handleAddQuote={handleAddQuote}
                              setCreateQuote={setCreateQuote}
                              createQuote={createQuote}
                              userConfigs={userConfigs}
                              userConfigsuom={userConfigsuom}
                              setSnackbarMSG={setSnackbarMSG}
                              setSnackbarSeverity={setSnackbarSeverity}
                              setOpenSnackbar={setOpenSnackbar}
                              catalogueItems={catalogueItems}
                              setCatalogueItems={setCatalogueItems}
                              handleAddToCatalogue={handleAddToCatalogue}
                              handleAddToPlans={handleAddToPlans}
                              allCatalogue={allCatalogue}
                              setTempGroups={setTempGroups}
                              setWantToCallGetJobPlan={setWantToCallGetJobPlan}
                              setRoundLoader={setRoundLoader}
                              calledFrom={props.calledFrom}
                              editableJobplan={editableJobplan}
                              estimateStatus={estimateStatus}
                            />
                          ))
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </Box>
              {openSnackbar && (
                <CustomizedSnackbars
                  openSnackbar={openSnackbar}
                  setOpenSnackbar={setOpenSnackbar}
                  snackbarMSG={snackbarMSG}
                  severity={snackbarSeverity}
                />
              )}
            </Paper>
          </Grid>
          {props.type === "estimate" ? (
            <Grid item xs={9}>
              <HeaderTotal
                quote={quote}
                setQuote={setQuote}
                createQuote={createQuote}
                type={props.type}
                handleSaveOuter={handleSaveOuter}
                handleCancelOuter={handleCancelOuter}
                disableSave={disableSave}
                rows={rows}
                setRows={setRows}
                setCreateQuote={setCreateQuote}
                setSnackbarMSG={setSnackbarMSG}
                setSnackbarSeverity={setSnackbarSeverity}
                setOpenSnackbar={setOpenSnackbar}
                wantToCallGetJobPlan={wantToCallGetJobPlan}
                setWantToCallGetJobPlan={setWantToCallGetJobPlan}
                setOpenErrDialog={setOpenErrDialog}
                setErrorCode={setErrorCode}
                setErrorMsg={setErrorMsg}
                setValue={props.setValue}
                checkedJobPlanLineItemsForPlans={checkedJobPlanLineItemsForPlans}
                setPlanDrawerOpen={setPlanDrawerOpen}
              />
            </Grid>
          ) : (
            <>
              {!disableSave && (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    align="right"
                    onClick={handleSaveOuter}
                    style={{
                      marginRight: "10px",
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    align="right"
                    onClick={handleCancelOuter}
                  >
                    Cancel
                  </Button>
                </>
              )}
            </>
          )}
          {/* <Grid item xs={2} style={{ position: "sticky", bottom: "50px" }}> */}
          {/* <> */}
          {/* {props.type === "estimate" && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}>
                  <div>
                    <Quoterequests createQuote={createQuote} is_estimateQuote={true} estimateData={estimateData} />
                  </div>
                  <div></div>
                </div>
              )} */}
          {/*
              {disableSave ? (
                <></>
              ) : (
                <>
                  <Button variant='contained' color='primary' align='right' onClick={handleSaveOuter}>
                    Save
                  </Button>
                  <Button variant='contained' color='secondary' align='right' onClick={handleCancelOuter}>
                    Cancel
                  </Button>
                </>
              )}
            </>
          </Grid> */}
          {/* {props.type === "estimate" && (
            <Grid item xs={10}>
              <HeaderTotal />
            </Grid>
          )} */}
          <Grid item xs={12} /* style={{ position: "sticky", bottom: "-10px" }} */>
            <Box m={1} py={2}>
              <div>
                <div>
                  {checkedJobPlanLineItems.length != 0 && (
                    <CreateCatalogueFromJobplanItem
                      checkedJobPlanLineItems={checkedJobPlanLineItems}
                      userConfigs={userConfigs}
                      userConfigsuom={userConfigsuom}
                      allCatalogue={allCatalogue}
                    />
                  )}
                </div>
              </div>
            </Box>
          </Grid>
        </Grid>
      )}
      {openErrDialog && (
        <ErrorDialog
          open={openErrDialog}
          setOpen={setOpenErrDialog}
          errorCode={errorCode}
          errorMessage={errorMsg}
        />
      )}
    </React.Fragment>
  );
}
