/** @format */

import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import { makeStyles } from "@mui/styles";
import TableRow from "@mui/material/TableRow";
import DeleteIcon from "@mui/icons-material/Delete";
import EmptyEstimatesDialog from "../UI/EmptyEstimatesDialog";
import ProgressBar from "../UI/ProgressBar";
import SimpleBackdrop from "../UI/SimpleBackdrop";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Collapse, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import EditIcon from "@mui/icons-material/Edit";
import CreateType from "./CreateType";
import { deleteJobTypebyID, getbyJobLineItemsJobTypeID, getCustomJobTypes } from "./helpers.buildingAPI";
import BuildingTypeLineItemTable from "./BuildingTypeLineItemTable";
import Grid from "@mui/material/Grid";

const useStyles = makeStyles({
  table: {
    minWidth: 150,
  },
  oneRow: {
    borderBottom: "none",
    width: "10vw",
  },
  rowDark: {
    color: "#fff",
    fontWeight: "bold",
    // borderBottom: "none",
    // width: "10vw",
    cursor: "pointer",
  },
  tableRow: {
    "&:hover": {
      // backgroundColor: "#cfecf8",
    },
  },
});
export default function BuildlingTypeHome() {
  const classes = useStyles();
  const [description, setDescription] = useState("");
  const [type, setType] = useState("");
  const [id, setId] = useState();
  const [delID, setDelId] = useState("");
  const [load, setLoad] = useState(true);
  const [delLoading, setDelLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [customer, setCustomer] = useState([]);
  const [wantToCallGet, setWantToCallGet] = useState(false);
  const [loadSave, setLoadSave] = useState(false);
  const [category, setCategory] = useState("");
  const [lineItems, setLineItems] = useState([]);
  const [tempRows, setTempRows] = React.useState([]);
  const [isEdit, setEdit] = React.useState(false);
  const [disableSave, setDisableSave] = React.useState(true);
  const [rows, setRows] = React.useState([]);

  const [jobTypeID, setJobTypeID] = useState();
  const [jobTypesArr, setJobTypesArr] = useState([]);
  useEffect(() => {
    getCustomJobTypes(setJobTypesArr, setLoad);
    console.log("jobTypesArr ", jobTypesArr);
  }, []);

  const [openLineItemRow, setOpenLineItemRow] = React.useState([]);

  const handleOpenCostinRow = (index, row) => {
    setJobTypeID(row.jobTypeId);
    // console.log("jobTypeID ", row.jobTypeId);
    getbyJobLineItemsJobTypeID(setRows, row.jobTypeId, setLoad, setTempRows);

    let collapseCopy = [...openLineItemRow];
    collapseCopy[index] = !collapseCopy[index];
    setOpenLineItemRow(collapseCopy);
  };
  console.log("rows : ", rows);

  const handleDeleteIcon = (e, row) => {
    deleteJobTypebyID(row.jobTypeId, setDelLoading, setOpenSnackbar);
    // var filterRows = rows.filter(
    //   (item) => item.jobTypeId !== delID
    //   );
    //   setRows(filterRows);
    //   console.log("filterRows : ",filterRows)
  };

  return (
    <React.Fragment>
      {delLoading && <SimpleBackdrop />}
      <CreateType
        id={id}
        setId={setId}
        description={description}
        setDescription={setDescription}
        type={type}
        setType={setType}
        customer={customer}
        setCustomer={setCustomer}
        setWantToCallGet={setWantToCallGet}
        setOpenSnackbar={setOpenSnackbar}
      />

      {load ? (
        <ProgressBar />
      ) : (
        <React.Fragment>
          {jobTypesArr.length === 0 ? ( //if estimates are empty then empty msg is shown
            <EmptyEstimatesDialog
              heading="Empty Types"
              msg="You don't have any building types here yet.
Click the 'CREATE NEW' button above to get started, or choose from a template"
            />
          ) : (
            <Grid item xs={6}>
              <Table size="medium" className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.rowDark}></TableCell>
                    <TableCell className={classes.rowDark}>Ref</TableCell>
                    <TableCell className={classes.rowDark}>Type</TableCell>
                    <TableCell className={classes.rowDark}>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {jobTypesArr.map((row, i) => (
                    <>
                      <TableRow
                        key={row.jobTypeId}
                        hover
                        sx={{
                          "& > *": {
                            borderBottom: "unset",
                          },
                        }}
                        // style={{ cursor: "pointer" }}
                        jobTypeId={row.jobTypeId}
                        className={classes.tableRow}
                      >
                        <TableCell className={classes.oneRow}>
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => handleOpenCostinRow(i, row)}
                          >
                            {openLineItemRow[i] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                          </IconButton>
                        </TableCell>
                        <TableCell className={classes.oneRow}>Q{row.jobTypeNo}</TableCell>
                        <TableCell className={classes.oneRow}>{row.jobType}</TableCell>
                        <TableCell className={classes.oneRow}>
                          <ButtonGroup variant="contained" aria-label="outlined primary button group">
                            <Button
                              size="small"
                              color="primary"
                              newId={row.jobTypeId}
                              // onClick={(e) => handleLocation(row.jobTypeId)}
                              value={row.jobTypeId}
                            >
                              {" "}
                              <EditIcon name="edit" />
                            </Button>
                            <Button
                              size="small"
                              color="secondary"
                              value={row.jobTypeId}
                              onClick={(e) => handleDeleteIcon(e, row)}
                            >
                              <DeleteIcon value={row.jobTypeId} />
                            </Button>
                          </ButtonGroup>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{
                            paddingBottom: 0,
                            paddingTop: 0,
                          }}
                          colSpan={12}
                        >
                          <Collapse in={openLineItemRow[i]} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                              <Typography variant="h6" gutterBottom component="div">
                                Responses
                              </Typography>
                              {/* <BuildingTypeLineItemTable
                                setRows={setRows}
                                rows={rows}
                                jobTypeID={jobTypeID}
                                category={category}
                                row={row}
                                loadSave={loadSave}
                                setLoadSave={setLoadSave}
                                isEdit={isEdit}
                                setEdit={setEdit}
                                setDisableSave={setDisableSave}
                                myrow={row}
                                setTempRows={setTempRows}
                              /> */}
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
                </TableBody>
              </Table>
            </Grid>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
