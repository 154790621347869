/** @format */

import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import ButtonGroup from "@mui/material/ButtonGroup";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CreateIcon from "@mui/icons-material/Create";
import { Button, Snackbar } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import AddIcon from "@mui/icons-material/Add";
import { getbyJobLineItemsJobTypeID } from "./helpers.buildingAPI";
import Alert from "@mui/material/Alert";
import SimpleBackdrop from "../UI/SimpleBackdrop";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import EmptyEstimatesDialog from "../UI/EmptyEstimatesDialog";

const useStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});
// {id:1, description: 'nik desc',type:'Draft', qty: 3 ,uom:'m2',unitCost: "3",gst: "2",markupPercentage: "20"}
export default function BuildingTypeLineItemTable({
  setRows,
  rows,
  jobTypeID,
  category,
  lineItems,
  row,
  loadSave,
  setLoadSave,
  isEdit,
  setEdit,
  setDisableSave,
  myrow,
  tempRows,
  setTempRows,
  setFinalCostings,
}) {
  const classes = useStyles();
  const [inputList, setInputList] = useState(myrow.lineItems);
  const [open, setOpen] = React.useState(false);

  //---- code to redirect quotechip to open drawer --------
  const [getQuoteDataID, setGetQuoteDataID] = useState([]);
  const [secondLoader, setSecondLoader] = useState(true);
  const handleChip = (row, i) => {
    console.info("You clicked the Chip.", row, i);
    // getQuoteDataAPI(row.quoteId, setGetQuoteDataID, setSecondLoader); //get quote
  }; //----ended----

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const handleEdit = (i) => {
    setEdit(true);
  };
  const handleCancel = (i) => {
    getbyJobLineItemsJobTypeID(setRows, row.jobTypeId, setLoadSave, setTempRows);
    setEdit(false);
    setDisableSave(true);
  };
  console.log("inputlist : ", inputList);
  const handleAdd = () => {
    setDisableSave(false);
    if (inputList.length === 0) {
      var lastBeforeRowLineId = 0;
    } else {
      var lastBeforeRowLineId = inputList[inputList.length - 1].lineItemSequenceId;
    }
    setEdit(true);
    var newElement = {
      lineItemId: null,
      categorySequenceId: row.categorySequenceId,
      lineItemSequenceId: lastBeforeRowLineId + 1,
      category: row.category,
      description: "",
      type: null,
      qty: 0,
      uom: null,
      unitCost: 0,
      gst: "11.0",
      markupPercentage: 0,
    };
    setInputList([...inputList, newElement]);
    setTempRows(inputList);
    console.log("rows ", rows);
  };

  const handleInputChange = (e, index) => {
    setDisableSave(false);
    setDisableSave(false);
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
    setTempRows(inputList);
  };

  const clearLineItem = (row) => {
    if (row.lineItemId === null) {
      var listfilter = inputList.filter(function (el) {
        return el.lineItemId != null;
      });
    } else {
      var listfilter = inputList.filter(function (el) {
        return el.lineItemId != row.lineItemId && el.lineItemId != null;
      });
    }
    let count = 1;
    var test = listfilter.filter(function (el) {
      el.lineItemSequenceId = count++;
      return el;
    });

    setInputList(test);
    rows.filter(function (element) {
      if (row.categorySequenceId === element.categorySequenceId) {
        element.lineItems = test;
      }
    });
    setDisableSave(false);
  };
  // code to handle gst change 10 or 0 percent
  const handleChangeGST = (e, i, row) => {
    // console.log("before ",row.gst);
    if (!e.target.checked) {
      row.gst = 0;
    } else {
      row.gst = 10;
    }
    const list = [...inputList];
    list[i][e.target.name] = e.target.value;
    setInputList(list);
    setTempRows(inputList);
    setDisableSave(false);
    console.log("after ", row.gst);
  };
  // const handleInputChange = (e, index) => {
  //   setDisableSave(false);
  //   setDisableSave(false);
  //   const { name, value } = e.target;
  //   const list = [...inputList];
  //   list[index][name] = value;
  //   setInputList(list);
  //   setTempRows(inputList)

  // };

  return (
    <Box>
      <Table size="small" aria-label="a dense table">
        {loadSave && <SimpleBackdrop />}
        <TableBody style={{ boxShadow: "none" }}>
          <Box margin={1}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div></div>
              <div>
                <Snackbar
                  open={open}
                  autoHideDuration={6000}
                  onClose={handleClose}
                  className={classes.snackbar}
                >
                  <Alert onClose={handleClose} severity="success" variant="filled">
                    Costings saved successfully!
                  </Alert>
                </Snackbar>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Box component="span" p={1}>
                    <ButtonGroup variant="contained" aria-label="contained primary button group">
                      <Button variant="contained" color="primary" onClick={handleAdd}>
                        <AddIcon onClick={handleAdd} />
                        ADD
                      </Button>
                      {isEdit ? (
                        <React.Fragment>
                          {inputList.length !== 0 && (
                            <React.Fragment>
                              <Button color="secondary" align="right" onClick={handleCancel}>
                                <ClearIcon fontSize="small" />
                                Cancel
                              </Button>
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          {inputList.length !== 0 && (
                            <Button align="right" onClick={handleEdit}>
                              <CreateIcon />
                              EDIT
                            </Button>
                          )}
                        </React.Fragment>
                      )}
                    </ButtonGroup>
                  </Box>
                </div>
              </div>
            </div>
            {inputList.length === 0 ? (
              <EmptyEstimatesDialog
                heading="Add some building type lineitems in this category"
                msg="Add some lineitems here to Save this build type. You can add using the side button present on the right side the screen"
              />
            ) : (
              <Table
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="a dense table"
                id="estimationCosting"
                className={" estimationCosting"}
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Code</TableCell>
                    <TableCell align="left" width="30%">
                      Description
                    </TableCell>
                    <TableCell align="center">Type</TableCell>
                    <TableCell align="center">QTY</TableCell>
                    <TableCell align="center">UOM</TableCell>
                    <TableCell align="center">UniCost</TableCell>
                    <TableCell align="center">GST</TableCell>
                    <TableCell align="center">Markup</TableCell>
                    <TableCell align="center">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {inputList.map((row, i) => {
                    return (
                      <React.Fragment>
                        {isEdit ? (
                          <React.Fragment>
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                              key={row.lineItemId}
                            >
                              <TableCell align="center">{row.lineItemSequenceId}</TableCell>
                              <TableCell align="left">
                                <input
                                  style={{
                                    width: "100%",
                                    border: "0.1px solid black",
                                  }}
                                  value={row.description}
                                  name="description"
                                  onChange={(e) => handleInputChange(e, i)}
                                />
                              </TableCell>
                              <TableCell align="center">
                                <select
                                  style={{
                                    width: "100%",
                                    border: "0.1px solid black",
                                  }}
                                  value={row.type}
                                  name="type"
                                  onChange={(e) => handleInputChange(e, i)}
                                >
                                  <option value=""></option>
                                  <option value="Labour">Labour</option>
                                  <option value="Supply">Supply</option>
                                  <option value="Supply and Install">Supply and Install</option>
                                </select>
                              </TableCell>
                              <TableCell align="center">
                                <input
                                  style={{
                                    width: "50px",
                                    border: "0.1px solid black",
                                  }}
                                  type="number"
                                  value={row.qty}
                                  name="qty"
                                  onChange={(e) => handleInputChange(e, i)}
                                />
                              </TableCell>
                              <TableCell align="center">
                                <select
                                  style={{
                                    width: "100%",
                                    border: "0.1px solid black",
                                  }}
                                  value={row.uom}
                                  name="uom"
                                  onChange={(e) => handleInputChange(e, i)}
                                >
                                  <option value=""></option>
                                  <option value="m3">m3</option>
                                  <option value="m2">m2</option>
                                  <option value="ea">ea</option>
                                  <option value="lm">lm</option>
                                </select>
                              </TableCell>
                              <TableCell align="center">
                                <input
                                  style={{
                                    width: "66px",
                                    border: "0.1px solid black",
                                  }}
                                  type="number"
                                  value={row.unitCost}
                                  name="unitCost"
                                  onChange={(e) => handleInputChange(e, i)}
                                />
                              </TableCell>
                              <TableCell align="center">
                                {row.gst >= 10.0 ? (
                                  <Checkbox
                                    size="small"
                                    value={10}
                                    defaultChecked
                                    onChange={(e) => handleChangeGST(e, i, row)}
                                    // onChange={(e) =>{if(!e.target.checked){row.gst=0;}else{row.gst=10;}}}
                                  />
                                ) : (
                                  <Checkbox
                                    size="small"
                                    value={0}
                                    // defaultChecked
                                    onChange={(e) => handleChangeGST(e, i, row)}
                                    // onChange={(e) =>{if(!e.target.checked){row.gst=0;}else{row.gst=10;}}}
                                  />
                                )}
                                {/* <h3>{row.gst}</h3> */}
                              </TableCell>
                              <TableCell align="center">
                                <input
                                  style={{
                                    width: "66px",
                                    border: "0.1px solid black",
                                  }}
                                  type="number"
                                  value={row.markupPercentage}
                                  name="markupPercentage"
                                  onChange={(e) => handleInputChange(e, i)}
                                />
                              </TableCell>
                              <TableCell align="center">
                                {isEdit && (
                                  <Button
                                    size="small"
                                    className="mr10"
                                    key={row.lineItemId}
                                    onClick={(e) => clearLineItem(row)}
                                  >
                                    <ClearIcon fontSize="small" />
                                  </Button>
                                )}
                              </TableCell>
                            </TableRow>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <TableRow
                              key={row.lineItemId}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell align="center">{row.lineItemSequenceId}</TableCell>
                              <TableCell width="30%" align="left">
                                {row.description}{" "}
                              </TableCell>
                              <TableCell align="center"> {row.type}</TableCell>
                              <TableCell align="center">{row.qty} </TableCell>
                              <TableCell align="center">{row.uom}</TableCell>
                              <TableCell align="center">{row.unitCost}</TableCell>
                              <TableCell align="center"> {row.gst}</TableCell>
                              <TableCell align="center">{row.markupPercentage}</TableCell>
                              <TableCell align="center">-</TableCell>
                            </TableRow>
                          </React.Fragment>
                        )}
                      </React.Fragment>
                    );
                  })}
                </TableBody>
              </Table>
            )}
          </Box>
        </TableBody>
      </Table>
    </Box>
  );
}
