/** @format */

import React, { useState, useMemo, useEffect } from "react";
import { Switch, Route, BrowserRouter as Router, Redirect } from "react-router-dom";
import OAuthcallback from "./oauth/OAuthcallback";
import OAuthlogout from "./oauth/OAuthlogout";
import OAuthProvider from "./oauth/OAuthContext";
import ProtectedRoute from "./components/common/ProtectedRoute";
import Navigator from "./components/navigators/NavigatorSideBar";
import EstimateNav from "./components/navigations/EstimateNav";
import Dashboard from "./components/dashboard/Dashboard";
// import TimeLine from "./components/timeline/TimeLine";
// import TimeLine2 from "./components/timeline/TimeLine2";
// import TimeLine3 from "./components/timeline/TimeLine3";
// import TimeLineBeta from "./components/timeline/TimeLineBeta";
// import TimeLineSyncFusion from "./components/timeline/TimeLIneSyncFusion";
// import TimeLineMonthly from "./components/timeline/Timline";
// import NativeTimeline from "./components/timeline/NativeTimeline";
// import CustomeTimeLine from "./components/timeline/CustomeTimeLine";
import Estimates from "./components/estimates/Estimates";
import Quoterequests from "./components/quotes/quoterequests/Quoterequests";
import QuoteResponse from "./components/quotes/quoteresponse/QuoteResponse";
import QuoteSent from "./components/quotes/quoteresponse/QuoteSent";
import Clients from "./components/clients/Clients";
import Contacts from "./components/contacts/Contacts";
import { makeStyles } from "@mui/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { blueGrey, red } from "@mui/material/colors";
import JobsNav from "./components/navigations/JobsNav";
//import BuildingNav from "./components/building_types/BuildingNav";
import JobsPage from "./components/jobs/jobs_home_page/JobsPage";
import BuildlingTypeHome from "./components/building_types/BuildingTypeHome";
import CostCenterHome from "./components/cost-center/CostCenterHome";
import CostCentreNav from "./components/navigations/CostCentreNav";
import ItemsMainRow from "./components/cost-center/ItemsMainRow";
import JobPlanNav from "./components/navigations/JobPlanNav";
import QuoteSentSuccessfully from "./components/quotes/quoteresponse/QuoteSentSuccessfully";
import { createBrowserHistory } from "history";
import TypesCompo from "./components/EstimatesSettings/TypesCompo";
import UomCompo from "./components/EstimatesSettings/UomCompo";
import Users from "./components/users/Users";
import Roles from "./components/roles/Roles";
import JobPlans from "./components/jobPlans/JobPlans";
import Variation from "./components/jobs/Variation/Variation";
import ClientDetails from "./components/clients/ClientDetails";
import Catalogue from "./components/catalogue/Catalogue";
import CatalogueNav from "./components/navigations/CatalogueNav";
import Webviewer from "./components/estimates/TakeoffPlans/Webviewer";
import Grid from "@mui/material/Grid";
import PagenotFound from "./components/ErrorHandler/PagenotFound";
import DailyLogs from "./components/daily-logs/DailyLogs";
import { Amplify, Auth } from "aws-amplify";
import { AuthConfig } from "./components/auth_config/amplify-config";
import Amplify_Component from "./components/login/Amplify_Component";
import "@aws-amplify/ui-react/styles.css";
import { saveToken } from "./utils/storage";

Amplify.configure(AuthConfig);

function App() {
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [toggleDark, settoggleDark] = useState(
    JSON.parse(localStorage.getItem("dark-mode")) || false
  );
  const primaryColorDark = blueGrey[900];
  const newblueGreyHover = blueGrey[800];
  const primaryColor = "#5A8C24";
  const primaryColorLight = "#EBF7DF";
  const primaryColorDarkmode = "#212533";
  const DarkBackground = "#333d49";
  const DarkBackgroundSelect = "rgb(90 140 35 / 15%)";
  const BorderColor = "rgba(0, 0, 0, 0.06)";
  const BorderDarkColor = "#919eab52";
  const DarkModeColor = "#333d49";
  const borderRadius = 10;
  const primaryButtonBg = "#F5FBEF";
  const secondaryButtonBg = "#FADFDC";
  const secondaryButtonColor = "#B7281B";
  const darkButton = DarkBackgroundSelect;

  const tableBackground = "#212b36";

  const white = "#ffffff";
  const black = "#000";
  let body_el = document.querySelector("body");
  if (toggleDark) {
    body_el.classList.add("dark");
    body_el.classList.remove("light");
  } else {
    body_el.classList.add("light");
    body_el.classList.remove("dark");
  }
  const toolbarHeight = "50px";
  const myTheme = createTheme({
    //for overriding listitem
    // overrides: {
    //   MuiListItem: {
    //     root: {
    //       "&$selected": {
    //         backgroundColor: toggleDark
    //           ? DarkBackgroundSelect
    //           : primaryColorLight,
    //         color: toggleDark ? primaryColor : primaryColor,
    //         MuiListItemIcon: {
    //           root: {
    //             color: "red",
    //           },
    //         },
    //         MuiTypography: {
    //           root: {
    //             color: toggleDark ? white : primaryColor,
    //           },
    //         },
    //         "&:hover": {
    //           backgroundColor: toggleDark
    //             ? DarkBackgroundSelect
    //             : primaryColorLight,
    //           color: toggleDark ? white : "",
    //         },
    //         MuiSvgIcon: {
    //           root: {
    //             "&$selected": {
    //               backgroundColor: toggleDark ? primaryColor : "red",
    //               color: toggleDark ? "#000" : "red",
    //             },
    //           },
    //         },
    //       },
    //     },
    //   },
    //   MuiList: {
    //     root: {
    //       color: toggleDark ? "#bdc1c6" : DarkBackground,
    //       MuiSvgIcon: {
    //         "&$selected": {
    //           color: toggleDark ? "red" : "red",
    //         },
    //       },
    //     },
    //   },
    //   MuiPaper: {
    //     root: {
    //       backgroundColor: toggleDark ? "rgb(255 255 255 / 1%)" : white,
    //     },
    //     elevation1: {
    //       boxShadow: "10px 10px 30px rgb(0 0 0 / 5%)",
    //     },
    //     rounded: {
    //       borderRadius: borderRadius,
    //     },
    //   },

    //   MuiAppBar: {
    //     root: {
    //       backgroundColor: toggleDark ? DarkBackground : white,
    //       boxShadow: "none",
    //       borderBottom: `2px solid`,
    //       borderBottomColor: toggleDark ? BorderDarkColor : BorderColor,
    //       "&.innerHeader_Nav": {
    //         backgroundColor: toggleDark ? DarkModeColor : white,
    //         color: toggleDark ? white : primaryColorDarkmode,
    //         borderRadius: borderRadius,

    //         marginBottom: 10,
    //       },
    //     },
    //   },

    //   MuiToolbar: {
    //     regular: {
    //       height: `${toolbarHeight}`,
    //       minHeight: "auto !important",
    //     },
    //   },

    //   MuiDrawer: {
    //     paperAnchorDockedLeft: {
    //       // backgroundColor: toggleDark ? DarkBackground : white,
    //       //borderRight: `2px solid`,
    //       //borderRightColor: toggleDark ? BorderDarkColor : BorderColor,
    //     },

    //     paperAnchorRight: {
    //       overflowX: "hidden",
    //       backgroundColor: toggleDark ? DarkBackground : "#fafafa",
    //     },
    //   },

    //   MuiDivider: {
    //     root: {
    //       backgroundColor: toggleDark ? BorderDarkColor : BorderColor,
    //       height: 2,
    //     },
    //   },

    //   MuiTab: {
    //     root: {
    //       padding: "15px 12px",
    //     },
    //   },

    //   MUIDataTable: {
    //     tableRoot: {
    //       border: "none",
    //     },
    //     paper: {
    //       borderRadius: borderRadius,
    //       boxShadow: "10px 10px 30px rgb(0 0 0 / 10%)",
    //       overflow: "hidden",
    //       border: toggleDark ? "2px solid" : "0px solid",
    //       borderColor: toggleDark ? "rgb(255 255 255 / 10%)" : "transparent",
    //     },
    //   },

    //   MUIDataTableHeadCell: {
    //     fixedHeader: {
    //       backgroundColor: toggleDark ? DarkModeColor : primaryColorLight,
    //       padding: "0 16px",
    //     },
    //     data: {
    //       color: toggleDark ? white : DarkBackground,
    //     },
    //   },

    //   MUIDataTableSelectCell: {
    //     headerCell: {
    //       // backgroundColor: toggleDark
    //       //     ? primaryColorDarkmode
    //       //     : primaryColor,

    //       backgroundColor: toggleDark ? DarkModeColor : primaryColorLight,
    //     },

    //     // fixedLeft: { position: "none" },
    //   },

    //   MuiCheckbox: {
    //     colorPrimary: {
    //       color: toggleDark ? white : primaryColor,
    //       background: "transparent",
    //       // "&$checked": {
    //       //     color: toggleDark ? white : primaryColor,
    //       // },
    //     },
    //   },

    //   MuiTable: {
    //     root: {
    //       borderBottom: `3px solid`,
    //       borderBottomColor: toggleDark ? "transparent" : primaryColor,
    //     },
    //   },

    //   MuiTableRow: {
    //     root: {
    //       // borderBottom: `1px solid`,
    //       //borderBottomColor: toggleDark ? BorderDarkColor : primaryColor,
    //       // "&:nth-of-type(odd):not(tr.MuiTableRow-root.MuiTableRow-head)":
    //       //     {
    //       //         backgroundColor: toggleDark
    //       //             ? "rgba(225 225 225 / 5%)"
    //       //             : primaryColorLight,
    //       //     },
    //     },
    //   },

    //   MuiTableCell: {
    //     // root: {
    //     //     color: white,
    //     // },
    //     body: {
    //       color: toggleDark ? "#bdc1c6" : "#000 ",
    //     },
    //     head: {
    //       color: toggleDark ? white : black,
    //       fontWeight: "bold",
    //       cursor: "pointer",
    //       backgroundColor: toggleDark ? DarkBackground : primaryColorLight,
    //       borderRight: `1px solid`,
    //       borderRightColor: toggleDark ? BorderDarkColor : BorderColor,
    //       "&:last-child": {
    //         borderRight: "none",
    //       },
    //     },

    //     root: {
    //       paddingTop: 4,
    //       paddingBottom: 4,
    //       "&:last-child": {
    //         paddingRight: 5,
    //       },

    //       "&$MuiSvgIcon-root": {
    //         display: "flex",
    //         root: {},
    //       },
    //     },

    //     paddingCheckbox: {
    //       padding: "0 16px",
    //     },
    //   },

    //   MuiStepper: {
    //     horizontal: {
    //       marginBottom: 30,
    //       borderRadius: 60,
    //       border: `1px solid #95bb6c`,
    //       boxShadow: "5px 5px 30px rgb(0 0 0 / 5%)",
    //     },

    //     root: {
    //       padding: 14,
    //     },
    //   },

    //   /* **************************** */

    //   // MuiTableHead: {
    //   //     root: {
    //   //         height: "40px",
    //   //         backgroundColor: toggleDark
    //   //             ? DarkBackground
    //   //             : DarkBackground,
    //   //     },
    //   // },

    //   // MuiSelect: {
    //   //     select: {
    //   //         "&:focus": {
    //   //             background: "red",
    //   //         },
    //   //     },
    //   // },

    //   // MuiTypography:{
    //   //   root:{
    //   //   color:toggleDark?white:'#000',
    //   // },
    //   // },

    //   MuiButton: {
    //     textPrimary: {
    //       color: toggleDark ? white : primaryColor,
    //       backgroundColor: toggleDark ? darkButton : primaryButtonBg,
    //       fontWeight: "bold",
    //       border: `1px solid ${primaryColor}`,

    //       "&:hover": {
    //         backgroundColor: toggleDark ? darkButton : primaryColor,
    //         color: toggleDark ? primaryColor : white,
    //       },
    //     },

    //     containedPrimary: {
    //       color: toggleDark ? white : primaryColor,
    //       backgroundColor: toggleDark ? darkButton : primaryButtonBg,
    //       fontWeight: "bold",
    //       border: `1px solid ${primaryColor}`,
    //       "&:hover": {
    //         backgroundColor: toggleDark ? darkButton : primaryColor,
    //         color: toggleDark ? primaryColor : white,
    //       },
    //     },

    //     containedSecondary: {
    //       color: toggleDark ? white : secondaryButtonColor,
    //       backgroundColor: toggleDark ? "brown" : secondaryButtonBg,
    //       fontWeight: "bold",
    //       border: `1px solid ${secondaryButtonColor}`,
    //       "&:hover": {
    //         color: toggleDark ? white : white,
    //         backgroundColor: toggleDark ? darkButton : secondaryButtonColor,
    //       },
    //     },

    //     contained: {
    //       boxShadow: "10px 10px 30px rgb(0 0 0 / 10%)",
    //     },

    //     disableElevation: {
    //       border: `1px solid #c5c5c5`,
    //     },
    //   },

    //   MuiIconButton: {
    //     colorPrimary: {
    //       color: toggleDark ? white : primaryColor,
    //       backgroundColor: toggleDark ? darkButton : primaryButtonBg,
    //       borderColor: primaryColor,

    //       "&:hover": {
    //         backgroundColor: toggleDark ? darkButton : primaryColor,
    //         color: toggleDark ? "#000" : white,
    //       },
    //     },
    //   },

    //   MuiButtonGroup: {
    //     contained: {
    //       boxShadow: "none",
    //     },
    //   },

    //   MuiInput: {
    //     root: {
    //       padding: "4px!important",
    //       fontSize: 13,
    //     },
    //   },
    // },

    //Mui 5 Overriding
    components: {
      MuiListItem: {
        styleOverrides: {
          root: {
            "&$selected": {
              backgroundColor: toggleDark ? DarkBackgroundSelect : primaryColorLight,
              color: toggleDark ? primaryColor : primaryColor,
              MuiListItemIcon: {
                root: {
                  color: "red",
                },
              },
              MuiTypography: {
                root: {
                  color: toggleDark ? white : primaryColor,
                },
              },
              "&:hover": {
                backgroundColor: toggleDark ? DarkBackgroundSelect : primaryColorLight,
                color: toggleDark ? white : "",
              },
              MuiSvgIcon: {
                root: {
                  "&$selected": {
                    backgroundColor: toggleDark ? primaryColor : "red",
                    color: toggleDark ? "#000" : "red",
                  },
                },
              },
            },
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            color: toggleDark ? "#bdc1c6" : DarkBackground,
            MuiSvgIcon: {
              "&$selected": {
                color: toggleDark ? "red" : "red",
              },
            },
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: toggleDark ? tableBackground : white,
            backgroundImage: "none",
          },
          // elevation1: {
          //   boxShadow: "10px 10px 30px rgb(0 0 0 / 5%)",
          // },
          rounded: {
            borderRadius: borderRadius,
          },
        },
      },

      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: toggleDark ? DarkBackground : white,
            boxShadow: "none",
            backgroundImage: "none",
            borderBottom: `2px solid`,
            borderBottomColor: toggleDark ? BorderDarkColor : BorderColor,
            color: toggleDark ? white : primaryColorDarkmode,

            "&.innerHeader_Nav": {
              backgroundColor: toggleDark ? DarkModeColor : white,
              color: toggleDark ? white : primaryColorDarkmode,
              borderRadius: borderRadius,

              marginBottom: 10,
            },
          },
        },
      },

      MuiToolbar: {
        styleOverrides: {
          regular: {
            height: `${toolbarHeight}`,
            minHeight: "auto !important",
          },
        },
      },

      MuiDrawer: {
        styleOverrides: {
          paperAnchorDockedLeft: {
            // backgroundColor: toggleDark ? DarkBackground : white,
            //borderRight: `2px solid`,
            //borderRightColor: toggleDark ? BorderDarkColor : BorderColor,
          },

          paperAnchorRight: {
            overflowX: "hidden",
            backgroundColor: toggleDark ? DarkBackground : "#fafafa",
          },
        },
      },

      MuiDivider: {
        styleOverrides: {
          root: {
            backgroundColor: toggleDark ? BorderDarkColor : BorderColor,
            height: 2,
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          indicator: {
            backgroundColor: primaryColor,
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            padding: "15px 12px",
          },
        },
      },

      MUIDataTable: {
        styleOverrides: {
          tableRoot: {
            border: "none",
          },
          paper: {
            borderRadius: borderRadius,
            boxShadow: "10px 10px 30px rgb(0 0 0 / 10%)",
            overflow: "hidden",
            border: toggleDark ? "2px solid" : "0px solid",
            borderColor: toggleDark ? BorderDarkColor : BorderColor,
          },
        },
      },

      MUIDataTableHeadCell: {
        styleOverrides: {
          fixedHeader: {
            backgroundColor: toggleDark ? DarkModeColor : primaryColorLight,
            padding: "0 16px",
          },
          data: {
            color: toggleDark ? white : DarkBackground,
          },
        },
      },

      MUIDataTableSelectCell: {
        styleOverrides: {
          headerCell: {
            // backgroundColor: toggleDark
            //     ? primaryColorDarkmode
            //     : primaryColor,

            backgroundColor: toggleDark ? DarkModeColor : primaryColorLight,
          },
        },

        // fixedLeft: { position: "none" },
      },

      MuiCheckbox: {
        styleOverrides: {
          colorPrimary: {
            color: toggleDark ? white : primaryColor,
            background: "transparent",
            // "&$checked": {
            //     color: toggleDark ? white : primaryColor,
            // },
          },
        },
      },

      MuiTable: {
        // styleOverrides: {
        //   root: {
        //     borderBottom: `3px solid`,
        //     borderBottomColor: toggleDark ? "transparent" : primaryColor,
        //     MuiTable: {
        //       styleOverrides: {
        //         root: {
        //           borderBottom: `4px solid`,
        //           borderBottomColor: toggleDark ? "transparent" : primaryColor,
        //         },
        //       },
        //     },
        //   },
        // },
      },

      MuiTableRow: {
        styleOverrides: {
          root: {
            // borderBottom: `1px solid`,
            //borderBottomColor: toggleDark ? BorderDarkColor : primaryColor,
            // "&:nth-of-type(odd):not(tr.MuiTableRow-root.MuiTableRow-head)":
            //     {
            //         backgroundColor: toggleDark
            //             ? "rgba(225 225 225 / 5%)"
            //             : primaryColorLight,
            //     },
          },
        },
      },

      MuiTableCell: {
        styleOverrides: {
          body: {
            color: toggleDark ? "#bdc1c6" : "#000 ",
            borderColor: toggleDark ? BorderDarkColor : BorderColor,
          },
          head: {
            color: toggleDark ? white : black,
            fontWeight: "bold",
            cursor: "pointer",
            backgroundColor: toggleDark ? DarkBackground : primaryColorLight,
            borderRight: `1px solid`,
            borderRightColor: toggleDark ? BorderDarkColor : BorderColor,
            //  whiteSpace: "nowrap",
            "&:last-child": {
              borderRight: "none",
            },
          },

          root: {
            paddingTop: 4,
            paddingBottom: 4,
            "&:last-child": {
              paddingRight: 5,
            },

            "&$MuiSvgIcon-root": {
              display: "flex",
              root: {},
            },
          },

          paddingCheckbox: {
            padding: "0 16px",
          },
        },
      },

      MuiStepper: {
        styleOverrides: {
          horizontal: {
            marginBottom: 30,
            borderRadius: 60,
            border: "1px solid",
            borderColor: toggleDark ? BorderDarkColor : BorderColor,
            boxShadow: "5px 5px 30px rgb(0 0 0 / 5%)",
          },

          root: {
            padding: 14,
          },
        },
      },

      /* **************************** */

      // MuiTableHead: {
      //     root: {
      //         height: "40px",
      //         backgroundColor: toggleDark
      //             ? DarkBackground
      //             : DarkBackground,
      //     },
      // },

      // MuiSelect: {
      //     select: {
      //         "&:focus": {
      //             background: "red",
      //         },
      //     },
      // },

      // MuiTypography:{
      //   root:{
      //   color:toggleDark?white:'#000',
      // },
      // },

      MuiButton: {
        styleOverrides: {
          textPrimary: {
            color: toggleDark ? white : primaryColor,
            backgroundColor: toggleDark ? darkButton : primaryButtonBg,
            fontWeight: "bold",
            border: `1px solid ${primaryColor}`,

            "&:hover": {
              backgroundColor: toggleDark ? darkButton : primaryColor,
              color: toggleDark ? primaryColor : white,
            },
          },

          containedPrimary: {
            color: toggleDark ? white : primaryColor,
            backgroundColor: toggleDark ? darkButton : primaryButtonBg,
            fontWeight: "bold",
            border: `1px solid ${primaryColor}`,
            "&:hover": {
              backgroundColor: toggleDark ? darkButton : primaryColor,
              color: toggleDark ? primaryColor : white,
            },
          },

          containedSecondary: {
            color: toggleDark ? white : secondaryButtonColor,
            backgroundColor: toggleDark ? "brown" : secondaryButtonBg,
            fontWeight: "bold",
            border: `1px solid ${secondaryButtonColor}`,
            "&:hover": {
              color: toggleDark ? white : white,
              backgroundColor: toggleDark ? darkButton : secondaryButtonColor,
            },
          },

          contained: {
            boxShadow: "none",
          },

          disableElevation: {
            border: `1px solid #c5c5c5`,
          },
        },
      },

      MuiIconButton: {
        styleOverrides: {
          colorPrimary: {
            color: toggleDark ? white : primaryColor,
            backgroundColor: toggleDark ? darkButton : primaryButtonBg,
            borderColor: primaryColor,

            "&:hover": {
              backgroundColor: toggleDark ? darkButton : primaryColor,
              color: toggleDark ? "#000" : white,
            },
          },
        },
      },

      MuiButtonGroup: {
        styleOverrides: {
          contained: {
            boxShadow: "none",
          },
        },
      },

      MuiInput: {
        styleOverrides: {
          root: {
            padding: "4px!important",
            fontSize: 13,
          },
        },
      },
    },

    //theme settings
    palette: {
      mode: toggleDark ? "dark" : "light",
      primary: {
        main: toggleDark ? white : primaryColor,
        contrastText: toggleDark ? `#000` : white,
        dark: toggleDark ? newblueGreyHover : primaryColorDark,
      },
      secondary: {
        main: toggleDark ? white : secondaryButtonColor,
        contrastText: toggleDark ? "#000" : white,
      },
    },
  });
  const history = createBrowserHistory();
  return (
    <ThemeProvider theme={myTheme}>
      <Amplify_Component>
        <OAuthProvider>
          <Router history={history}>
            <Switch>
              {/* <Route path="/" toggleDark={toggleDark} component={OAuthcallback} theme={myTheme} /> */}
              <Route path="/logout" toggleDark={toggleDark} component={OAuthlogout} />
              <Route path="/sent-quote" exact toggleDark={toggleDark} component={QuoteSent} />
              <Route path="/webviewer" exact toggleDark={toggleDark} component={Webviewer} />
              <Route
                path="/sent-quote/success"
                toggleDark={toggleDark}
                component={QuoteSentSuccessfully}
              />

              <ProtectedRoute path="/estimates">
                <Navigator
                  component={Estimates}
                  title="Estimates"
                  toggleDark={toggleDark}
                  settoggleDark={settoggleDark}
                  selectedIndex={1}
                  setSelectedIndex={setSelectedIndex}
                />
              </ProtectedRoute>

              <ProtectedRoute path="/job-plan/:jobPlanId/">
                <Navigator
                  component={JobPlanNav}
                  title="Job Plan"
                  toggleDark={toggleDark}
                  settoggleDark={settoggleDark}
                  selectedIndex={6}
                  setSelectedIndex={setSelectedIndex}
                />
              </ProtectedRoute>

              <ProtectedRoute path="/cost-centre/:costCentreId/">
                <Navigator
                  component={ItemsMainRow}
                  title="Cost Centre"
                  toggleDark={toggleDark}
                  settoggleDark={settoggleDark}
                  selectedIndex={14}
                  setSelectedIndex={setSelectedIndex}
                />
              </ProtectedRoute>
              <ProtectedRoute path="/cost-centre">
                <Navigator
                  component={CostCenterHome}
                  title="Cost Centre"
                  toggleDark={toggleDark}
                  settoggleDark={settoggleDark}
                  selectedIndex={14}
                  setSelectedIndex={setSelectedIndex}
                />
              </ProtectedRoute>
              <ProtectedRoute path="/users">
                <Navigator
                  component={Users}
                  title="Users"
                  toggleDark={toggleDark}
                  settoggleDark={settoggleDark}
                  selectedIndex={7}
                  setSelectedIndex={setSelectedIndex}
                />
              </ProtectedRoute>
              <ProtectedRoute path="/roles">
                <Navigator
                  component={Roles}
                  title="Roles"
                  toggleDark={toggleDark}
                  settoggleDark={settoggleDark}
                  selectedIndex={10}
                  setSelectedIndex={setSelectedIndex}
                />
              </ProtectedRoute>
              {/* <ProtectedRoute path="/building/types">
                <Navigator
                  component={BuildlingTypeHome}
                  title="Building Types"
                  toggleDark={toggleDark}
                  settoggleDark={settoggleDark}
                  selectedIndex={13}
                  setSelectedIndex={setSelectedIndex}
                />
              </ProtectedRoute> */}
              <ProtectedRoute path="/estimate/:estimateId/quoterequests/:estimateQuoteId">
                <Navigator
                  component={EstimateNav}
                  title="Estimates Costing"
                  toggleDark={toggleDark}
                  settoggleDark={settoggleDark}
                  selectedIndex={1}
                  setSelectedIndex={setSelectedIndex}
                />
              </ProtectedRoute>
              <ProtectedRoute path="/estimate/:estimateId/">
                <Navigator
                  component={EstimateNav}
                  title="Estimates"
                  toggleDark={toggleDark}
                  settoggleDark={settoggleDark}
                  selectedIndex={1}
                  setSelectedIndex={setSelectedIndex}
                />
              </ProtectedRoute>
              <ProtectedRoute path="/clientDetails/:emailAddress/">
                <Navigator
                  component={ClientDetails}
                  title="Client Details"
                  toggleDark={toggleDark}
                  settoggleDark={settoggleDark}
                  setSelectedIndex={setSelectedIndex}
                />
              </ProtectedRoute>
              <ProtectedRoute path="/jobs">
                <Navigator
                  component={JobsPage}
                  title="Jobs"
                  toggleDark={toggleDark}
                  settoggleDark={settoggleDark}
                  selectedIndex={3}
                  setSelectedIndex={setSelectedIndex}
                />
              </ProtectedRoute>
              <ProtectedRoute path="/job/:jobId">
                <Navigator
                  component={JobsNav}
                  title="Jobs"
                  toggleDark={toggleDark}
                  settoggleDark={settoggleDark}
                  selectedIndex={3}
                  setSelectedIndex={setSelectedIndex}
                />
              </ProtectedRoute>
              <ProtectedRoute path="/jobplans">
                <Navigator
                  component={JobPlans}
                  title="Job Plans"
                  toggleDark={toggleDark}
                  settoggleDark={settoggleDark}
                  selectedIndex={6}
                  setSelectedIndex={setSelectedIndex}
                />
              </ProtectedRoute>
              <ProtectedRoute exact path="/variations">
                <Navigator
                  component={Variation}
                  title="Variations"
                  toggleDark={toggleDark}
                  settoggleDark={settoggleDark}
                  selectedIndex={13}
                  setSelectedIndex={setSelectedIndex}
                />
              </ProtectedRoute>
              <ProtectedRoute exact path="/catalogue/:contactId/:catalogueId/items/">
                <Navigator
                  component={CatalogueNav}
                  title="Catalogue"
                  toggleDark={toggleDark}
                  settoggleDark={settoggleDark}
                  selectedIndex={15}
                  setSelectedIndex={setSelectedIndex}
                />
              </ProtectedRoute>
              <ProtectedRoute exact path="/catalogue">
                <Navigator
                  component={Catalogue}
                  title="Catalogue"
                  toggleDark={toggleDark}
                  settoggleDark={settoggleDark}
                  selectedIndex={15}
                  setSelectedIndex={setSelectedIndex}
                />
              </ProtectedRoute>

              <ProtectedRoute exact path="/quoterequests">
                <Navigator
                  component={Quoterequests}
                  title="Quotes"
                  toggleDark={toggleDark}
                  settoggleDark={settoggleDark}
                  selectedIndex={2}
                  setSelectedIndex={setSelectedIndex}
                />
              </ProtectedRoute>
              <ProtectedRoute exact path="/quoteresponse">
                <Navigator
                  component={QuoteResponse}
                  title="Quote Response"
                  toggleDark={toggleDark}
                  settoggleDark={settoggleDark}
                  selectedIndex={selectedIndex}
                  setSelectedIndex={setSelectedIndex}
                />
              </ProtectedRoute>
              <ProtectedRoute exact path="/clients">
                <Navigator
                  component={Clients}
                  title="Clients"
                  toggleDark={toggleDark}
                  settoggleDark={settoggleDark}
                  selectedIndex={4}
                  setSelectedIndex={setSelectedIndex}
                />
              </ProtectedRoute>
              <ProtectedRoute exact path="/contacts">
                <Navigator
                  component={Contacts}
                  title="Contacts"
                  toggleDark={toggleDark}
                  settoggleDark={settoggleDark}
                  selectedIndex={5}
                  setSelectedIndex={setSelectedIndex}
                />
              </ProtectedRoute>
              {/* <ProtectedRoute exact path="/timeline">
              <Navigator
                component={TimeLineBeta}
                title="Timeline"
                toggleDark={toggleDark}
                settoggleDark={settoggleDark}
                selectedIndex={9}
                setSelectedIndex={setSelectedIndex}
              />
              <Navigator
                component={TimeLine3}
                title="Timeline"
                toggleDark={toggleDark}
                settoggleDark={settoggleDark}
                selectedIndex={9}
                setSelectedIndex={setSelectedIndex}
              />
              <Navigator
                component={TimeLine}
                title="Timeline"
                toggleDark={toggleDark}
                settoggleDark={settoggleDark}
                selectedIndex={9}
                setSelectedIndex={setSelectedIndex}
              />
            </ProtectedRoute> */}
              {/* <ProtectedRoute exact path="/dailylogs">
                <Navigator
                  component={DailyLogs}
                  title="DailyLogs"
                  toggleDark={toggleDark}
                  settoggleDark={settoggleDark}
                  selectedIndex={16}
                  setSelectedIndex={setSelectedIndex}
                />
              </ProtectedRoute> */}
              <ProtectedRoute exact path="/estimatessetting/TYPES">
                <Navigator
                  component={TypesCompo}
                  title="Types"
                  toggleDark={toggleDark}
                  settoggleDark={settoggleDark}
                  selectedIndex={11}
                  setSelectedIndex={setSelectedIndex}
                />
              </ProtectedRoute>
              <ProtectedRoute exact path="/estimatessetting/UOM">
                <Navigator
                  component={UomCompo}
                  title="UOM"
                  toggleDark={toggleDark}
                  settoggleDark={settoggleDark}
                  selectedIndex={12}
                  setSelectedIndex={setSelectedIndex}
                />
              </ProtectedRoute>
              {/* <ProtectedRoute exact path="/">
                <Navigator
                  component={Dashboard}
                  title="Dashboard"
                  toggleDark={toggleDark}
                  settoggleDark={settoggleDark}
                  selectedIndex={0}
                  setSelectedIndex={setSelectedIndex}
                />
              </ProtectedRoute> */}
              <ProtectedRoute exact path="/">
                <Redirect to="/estimates" />
              </ProtectedRoute>
              <Route path="*" component={PagenotFound} />
            </Switch>
          </Router>
        </OAuthProvider>
      </Amplify_Component>
    </ThemeProvider>
  );
}

export default App;
