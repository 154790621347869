import React from "react";

// ** MUI Components

import { Authenticator, ThemeProvider, Theme, useTheme } from "@aws-amplify/ui-react";
import { components, formFields, theme } from "./AuthenticatorUI";

function Amplify_Component({ children }) {
  return (
    <>
      {
        <ThemeProvider theme={theme}>
          <Authenticator formFields={formFields} components={components} hideSignUp={true}>
            {children}
          </Authenticator>
        </ThemeProvider>
      }
    </>
  );
}

export default Amplify_Component;
