import React from "react";
import Post from "./Post";

function DailyLogs() {
  return (
    <div>
      <Post />
      <Post />
      <Post />
      <Post />
      <Post />
      <Post />
      <Post />
    </div>
  );
}

export default DailyLogs;
