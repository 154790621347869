import React from "react";
import { CircularProgress, useTheme } from "@mui/material";

export default function Loading() {
  const theme = useTheme();
  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        height: "100vh",
        width: "100vw",
      }}
    >
      <CircularProgress />
      <span
        style={{
          justifyContent: "center",
          position: "fixed",
          top: "55%",
          color: theme.palette.primary.main,
        }}
      >
        Loading...please wait
      </span>
    </div>
  );
}
