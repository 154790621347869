import React, { useEffect, useContext, useState } from "react";
import { Route, useLocation } from "react-router-dom";
import { OAuthCtx } from "../../oauth/OAuthContext";
import { setupAuthInterceptor } from "../../utils/apis";
import apis from "../../utils/apis";
import {
  saveTargetPath,
  isLoggedIn,
  getIdToken,
  getAccessToken,
  setPermissions,
} from "../../utils/storage";
import { getUserProfile } from "../../oauth/OAuthcallback.helpers";
import Loading from "../UI/Loading";
import { Auth } from "aws-amplify";

const ProtectedRoute = ({ component: Component, path, ...rest }) => {
  const location = useLocation();
  const [LoggedIn, setLoggedIn] = useState(false);
  const { getAuthState, redirectUrl, updateAuthState } = useContext(OAuthCtx);
  setupAuthInterceptor(getAuthState, redirectUrl);
  const permissions = sessionStorage.getItem("permissions");
  var currentSession = null;

  useEffect(async () => {
    saveTargetPath(location.pathname);

    currentSession = await Auth.currentSession();
    updateAuthState({
      isLoggedIn: currentSession != null,
      id_token: currentSession != null ? currentSession.getIdToken().getJwtToken() : null,
      access_token: currentSession != null ? currentSession.getAccessToken().getJwtToken() : null,
      expire_by: new Date().getTime() + currentSession.getIdToken().getExpiration() * 1000,
    });

    if (permissions === null) {
      const res_profile = await getUserProfile();

      if (apis.isResponseOK(res_profile)) {
        setPermissions(res_profile.data.permissions);
        setLoggedIn(true);
      }
    }

    setLoggedIn(true);
  });

  if (LoggedIn) {
    return (
      <Route exact path={path} {...rest} render={(props) => <Component {...rest} {...props} />} />
    );
  } else {
    return <Loading />;
  }
};

export default ProtectedRoute;
