/** @format */

import React, { useState, useEffect } from "react";
import { Button, ButtonGroup, Checkbox, Snackbar, Table, TextField } from "@mui/material";
import SimpleBackdrop from "../../UI/SimpleBackdrop";
import TableBody from "@mui/material/TableBody";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import CreateIcon from "@mui/icons-material/Create";
import EmptyEstimatesDialog from "../../UI/EmptyEstimatesDialog";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Delete from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  updateSpecificationsByEstimateIdCopy,
  updateSpecificationsByJobPlanIdCopy,
} from "./specifications.helper";
import { makeStyles } from "@mui/styles";
import { Tooltip, Zoom } from "@mui/material";
import { uuid } from "uuidv4";

const useStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

export default function SpecificationsGroupItemsTable({
  rows,
  groupName,
  groupCode,
  jobPlanId,
  loadSave,
  setDisableSave,
  jobPlanObject,
  setJobPlanObject,
  tempRows,
  setTempRows,
  setRows,
  setOpenedItemRow,
  setOpenSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
  type,
  estimateId,
  includesPrice,
  editableJobplan,
  setRoundLoader,
  estimateStatus,
}) {
  const classes = useStyles();

  const [inputList, setInputList] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [openItems, setOpenItems] = React.useState(false);
  const [index, setIndex] = React.useState();
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [itemRowToDelete, setItemRowToDelete] = React.useState();
  const [textFieldDisable, setTextFieldDisable] = React.useState(false);
  const [isEdit, setEdit] = React.useState(false);

  /* ---------------------------------------------------------------- */
  const handleConfirm = (row) => {
    console.log("setItemRowToDelete ", row);
    setItemRowToDelete(row);
    setShowConfirm(true);
  };

  /* ---------------------------------------------------------------- */

  const handleNo = () => {
    setShowConfirm(false);
  };

  /* ---------------------------------------------------------------- */
  const handleCancel = () => {
    setInputList(jobPlanObject); /* Reset our old data after cancel edit/add operation  */
    setJobPlanObject(jobPlanObject);
    setEdit(false);
    setDisableSave(true);
  };

  /* ---------------------------------------------------------------- */

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  /* ---------------------------------------------------------------- */
  const handleEdit = (i) => {
    setEdit(true);
  };

  /* ---------------------------------------------------------------- */

  const deleteItemNonEditMode = () => {
    let deletedData = filterInputListForClearAndDeleteLineItem(itemRowToDelete);
    updateValuesInRows(deletedData);
    setJobPlanObject(deletedData); //it is for permenetly store data after api call
    setShowConfirm(false);
  };

  /* ---------------------------------------------------------------- */

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    var list = JSON.parse(JSON.stringify(inputList));
    list[index][name] = value;
    setInputList(list);
  };

  /* -------------------------------------------------------- */
  const clearLineItem = (row) => {
    let deletedData = filterInputListForClearAndDeleteLineItem(row);
    setInputList(deletedData);
    setDisableSave(true);
  };

  /* ------------------------Common function------------------------- */
  function filterInputListForClearAndDeleteLineItem(deleteId) {
    let filterList = inputList.filter((el) => {
      return deleteId.itemCode != el.itemCode;
    });

    let count = 1;
    var test = filterList.filter(function (el) {
      el.itemCode = count++;
      return el;
    });
    return test;
  }
  /* ---------------------------------------------------------------- */
  // var tempId = uuid();

  /* ---------------------------------------------------------------- */

  const handleAdd = () => {
    setEdit(true);
    // setCallGetJobPlan(true);
    var newElement;
    // if (inputList.length === 0) {
    //   var lastBeforeRowLineId = 0;
    // } else {
    //   var lastBeforeRowLineId = inputList[inputList.length - 1].itemCode;
    // }
    // if (includesPrice) {
    //   newElement = {
    //     itemId: null,
    //     itemCode: ++lastBeforeRowLineId,
    //     itemGroupCode: groupCode,
    //     itemGroupName: groupName,
    //     itemDescription: "",
    //     price: "",
    //     included: false,
    //   };
    // } else {
    //   newElement = {
    //     itemId: null,
    //     itemCode: ++lastBeforeRowLineId,
    //     itemGroupCode: groupCode,
    //     itemGroupName: groupName,
    //     itemDescription: "",
    //     price: "",
    //     included: false,
    //   };
    // }
    newElement = {
      itemId: null,
      itemCode: "",
      itemGroupCode: groupCode,
      itemGroupName: groupName,
      itemDescription: "",
      totalAmount: "0",
      included: false,
    };
    setInputList([...inputList, newElement]);
  };

  /* ------------------------Common function------------------------- */

  function updateValuesInRows(finalJobLineObj) {
    rows.filter((item) => {
      finalJobLineObj.filter((el) => {
        if (item.groupCode === el.itemGroupCode && item.groupName === el.itemGroupName) {
          item.items = finalJobLineObj;
        }
      });
    });
    if (type == "jobPlan") {
      updateSpecificationsByJobPlanIdCopy(
        jobPlanId,
        rows,
        setTempRows,
        setOpenSnackbar,
        setSnackbarMSG,
        setSnackbarSeverity,
        setRoundLoader
      );
    } else {
      updateSpecificationsByEstimateIdCopy(
        estimateId,
        rows,
        setTempRows,
        setOpenSnackbar,
        setSnackbarMSG,
        setSnackbarSeverity,
        setRoundLoader
      );
    }
  }
  /* -------------------------------------------------------- */

  const handlesLineItemSave = () => {
    var finalJobLineObj = inputList.filter((el) => {
      if (el.itemDescription != "") {
        return el;
      }
    });
    setJobPlanObject(finalJobLineObj);

    updateValuesInRows(finalJobLineObj);
    setOpenItems({ [index]: false });
    setEdit(false);
    setDisableSave(true);
    console.log("handlesLineItemSave called ");
  };

  /* ---------------------------------------------------------------- */
  const handleIncluded = (e, i) => {
    var list = JSON.parse(JSON.stringify(inputList));
    if (e.target.checked) {
      // setTextFieldDisable(true);
      list[i]["included"] = true;
      list[i]["totalAmount"] = "0";
      setInputList(list);
    } else {
      // setTextFieldDisable(false);
      list[i]["included"] = false;
      setInputList(list);
    }
  };
  /* ---------------------------------------------------------------- */

  useEffect(() => {
    /* i copied object using JSON method bcz  i have to copy object without any object refrence */

    setInputList(JSON.parse(JSON.stringify(jobPlanObject)));
    console.log("SpecificationsGroupItemsTable ", jobPlanObject);
  }, [jobPlanObject]);

  /* ---------------------------------------------------------------- */
  console.log("InputList", inputList);

  return (
    <Box>
      <Table
        size="small"
        aria-label="a dense table"
        sx={{ borderBottom: 0, backgroundColor: "rgb(106 106 106 / 5%)" }}
      >
        {loadSave && <SimpleBackdrop />}
        <TableBody style={{ boxShadow: "none" }}>
          <Box pt={3} pb={3}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Box component="span" p={1}>
                    {inputList.length !== 0 ? (
                      <ButtonGroup variant="contained" aria-label="contained primary button group">
                        {isEdit ? (
                          <ButtonGroup
                            variant="contained"
                            aria-label="contained primary button group"
                          >
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={handleAdd}
                              size="small"
                            >
                              <AddIcon fontSize="small" />
                              ADD
                            </Button>
                            <Button
                              variant="contained"
                              color="secondary"
                              align="right"
                              onClick={handleCancel}
                              size="small"
                            >
                              <ClearIcon fontSize="small" />
                              Cancel
                            </Button>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={handlesLineItemSave}
                              size="small"
                            >
                              <AddIcon fontSize="small" />
                              SAVE
                            </Button>
                          </ButtonGroup>
                        ) : (
                          <ButtonGroup
                            variant="contained"
                            aria-label="contained primary button group"
                          >
                            {type === "jobPlan" ? (
                              editableJobplan ? (
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={handleAdd}
                                  size="small"
                                >
                                  <AddIcon fontSize="small" />
                                  ADD
                                </Button>
                              ) : (
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={handleAdd}
                                  size="small"
                                  disabled
                                >
                                  <AddIcon fontSize="small" />
                                  ADD
                                </Button>
                              )
                            ) : (
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={handleAdd}
                                size="small"
                                disabled={estimateStatus !== "DRAFT" ? true : false}
                              >
                                <AddIcon fontSize="small" />
                                ADD
                              </Button>
                            )}
                            {type === "jobPlan" ? (
                              editableJobplan ? (
                                <Button
                                  variant="contained"
                                  color="primary"
                                  align="right"
                                  onClick={handleEdit}
                                  size="small"
                                >
                                  <CreateIcon fontSize="small" />
                                  EDIT
                                </Button>
                              ) : (
                                <Button
                                  variant="contained"
                                  color="primary"
                                  align="right"
                                  onClick={handleEdit}
                                  size="small"
                                  disabled
                                >
                                  <CreateIcon fontSize="small" />
                                  EDIT
                                </Button>
                              )
                            ) : (
                              <Button
                                variant="contained"
                                color="primary"
                                align="right"
                                onClick={handleEdit}
                                size="small"
                                disabled={estimateStatus !== "DRAFT" ? true : false}
                              >
                                <CreateIcon fontSize="small" />
                                EDIT
                              </Button>
                            )}
                          </ButtonGroup>
                        )}
                      </ButtonGroup>
                    ) : (
                      <ButtonGroup variant="contained" aria-label="contained primary button group">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleAdd}
                          size="small"
                        >
                          <AddIcon onClick={handleAdd} fontSize="small" />
                          ADD
                        </Button>
                      </ButtonGroup>
                    )}
                  </Box>
                </div>
              </div>
            </div>
            {inputList.length < 1 ? (
              <EmptyEstimatesDialog
                heading="Add some specifications line items in this category"
                msg="Add some lineitems here to Save this specifications type. You can add using the side button present on the right side the screen"
              />
            ) : (
              <Table
                sx={{ minWidth: 650, borderBottom: 0 }}
                size="small"
                aria-label="a dense table"
                id="estimationCosting"
                className={" estimationCosting"}
              >
                <TableHead>
                  <TableRow>
                    <TableCell width="5%">Code</TableCell>
                    <TableCell width="auto">Description</TableCell>
                    {includesPrice && (
                      <TableCell align="center" width="5%">
                        Total Amount
                      </TableCell>
                    )}
                    {includesPrice && (
                      <TableCell align="center" width="5%">
                        Included
                      </TableCell>
                    )}

                    <TableCell align="right" width="5%">
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {inputList.map((row, i) => {
                    return (
                      <React.Fragment>
                        {isEdit ? (
                          <TableRow key={row.itemId}>
                            <TableCell>
                              {" "}
                              <TextField
                                variant="outlined"
                                className={classes.textField}
                                size="small"
                                fullWidth
                                value={row.itemCode}
                                name="itemCode"
                                onChange={(e) => handleInputChange(e, i)}
                              />
                            </TableCell>

                            <TableCell>
                              <TextField
                                variant="outlined"
                                className={classes.textField}
                                size="small"
                                fullWidth
                                value={row.itemDescription}
                                name="itemDescription"
                                onChange={(e) => handleInputChange(e, i)}
                              />
                            </TableCell>
                            {includesPrice && (
                              <TableCell>
                                <TextField
                                  variant="outlined"
                                  className={classes.textField}
                                  size="small"
                                  value={row.totalAmount}
                                  name="totalAmount"
                                  onChange={(e) => handleInputChange(e, i)}
                                  disabled={row.included ? true : false}
                                />
                              </TableCell>
                            )}
                            {includesPrice && (
                              <TableCell>
                                <Checkbox
                                  size="small"
                                  name="included"
                                  onChange={(e) => handleIncluded(e, i)}
                                />
                              </TableCell>
                            )}

                            <TableCell align="right">
                              {inputList.length > 1 && (
                                <ButtonGroup variant="contained" size="small">
                                  <Button
                                    size="small"
                                    color="secondary"
                                    className="mr10"
                                    key={row.itemId}
                                    onClick={(e) => clearLineItem(row)}
                                  >
                                    <ClearIcon fontSize="small" />
                                  </Button>
                                </ButtonGroup>
                              )}
                            </TableCell>
                          </TableRow>
                        ) : (
                          <React.Fragment>
                            <TableRow key={row.itemId}>
                              <TableCell>{row.itemCode}</TableCell>
                              <TableCell>{row.itemDescription} </TableCell>
                              {includesPrice && <TableCell>{row.totalAmount} </TableCell>}
                              {includesPrice && (
                                <TableCell>
                                  <Checkbox
                                    size="small"
                                    name="included"
                                    disabled
                                    defaultChecked={row.included ? true : false}
                                  />
                                </TableCell>
                              )}
                              <TableCell align="right">
                                {inputList.length > 1 && (
                                  <ButtonGroup
                                    variant="contained"
                                    aria-label="outlined primary button group"
                                  >
                                    <Tooltip
                                      title="ONLY DELETE ITEM"
                                      placement="top"
                                      TransitionComponent={Zoom}
                                      arrow
                                    >
                                      {type === "jobPlan" ? (
                                        editableJobplan ? (
                                          <Button
                                            size="small"
                                            color="secondary"
                                            className="mr10"
                                            key={row.itemId}
                                            onClick={(e) => handleConfirm(row)}
                                          >
                                            <Delete fontSize="small" />
                                          </Button>
                                        ) : (
                                          <Button
                                            size="small"
                                            color="secondary"
                                            className="mr10"
                                            key={row.itemId}
                                            onClick={(e) => handleConfirm(row)}
                                            disabled
                                          >
                                            <Delete fontSize="small" />
                                          </Button>
                                        )
                                      ) : (
                                        <Button
                                          size="small"
                                          color="secondary"
                                          className="mr10"
                                          key={row.itemId}
                                          onClick={(e) => handleConfirm(row)}
                                          disabled={estimateStatus !== "DRAFT" ? true : false}
                                        >
                                          <Delete fontSize="small" />
                                        </Button>
                                      )}
                                    </Tooltip>
                                  </ButtonGroup>
                                )}
                              </TableCell>
                            </TableRow>
                          </React.Fragment>
                        )}
                      </React.Fragment>
                    );
                  })}
                </TableBody>
              </Table>
            )}
          </Box>
        </TableBody>
      </Table>
      {showConfirm && (
        <div>
          <Dialog
            open={showConfirm}
            onClose={handleNo}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure to delete
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={deleteItemNonEditMode} color="primary" autoFocus>
                Yes
              </Button>
              <Button onClick={handleNo} color="primary" autoFocus>
                No
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </Box>
  );
}
