import React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";

export const secondaryListItems = (
  <div>
    <ListItem
      button
      onClick={async () => {
        await Auth.signOut();
        // Clear local storage
        localStorage.clear();
        sessionStorage.clear();
      }}
    >
      <ListItemIcon>
        <ExitToAppIcon />
      </ListItemIcon>
      <ListItemText primary="Logout" />
    </ListItem>
  </div>
);
