export const AuthConfig = {
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_OAUTH_CLIENT_ID,

    oauth: {
      domain: "https://dev.id.buildxap.com.au",
      redirectSignIn: "http://localhost:3000/",
      redirectSignOut: "http://localhost:3000/",
      responseType: "code",
    },
  },
  // Storage: {
  //   bucket: "wms-resourses-dev",
  //   region: "ap-southeast-2",
  //   level: "public",
  //   identityPoolId: "ap-southeast-2:fc146f26-84f3-4cd8-bc8d-96be7f62e0d7",
  // },
};
